import React from "react";
import ComplianceAddons from "./ComplianceAddons/ComplianceAddons";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import AdditionalAddons from "./AdditionalAddons/AdditionalAddons";
import { SnackbarProvider } from "notistack";

const AddonsManagement = (props) => {
  console.log("AddonsManagement is rerendered");
  return (
    <React.Fragment>
      <SnackbarProvider>
        <Modal
          isOpen={props.modal_addons}
          backdrop="static"
          centered
          size="lg"
          
        >
          <ModalHeader toggle={props.closeModal}>
            Add Ons List for Account Number: {props?.editAccount?.accountNumber}
          </ModalHeader>
          <ModalBody
            style={{
              maxHeight: "70vh", // Ensure the modal doesn't exceed 70% of the viewport height
              overflowY: "auto", // Allow scrolling within the modal body
            }}
          >
            <ComplianceAddons complianceAddOns={props.complianceAddOns} editAccount={props.editAccount}/>
            <AdditionalAddons addOns={props.addOns} editAccount={props.editAccount}/>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="light" onClick={props.closeModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </SnackbarProvider>
    </React.Fragment>
  );
};

export default AddonsManagement;

// compliance_status, costInsight_status, costOptimization_status, eventInsight_status, 