import React, { Component, useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Alert, Tooltip, Box, Button } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import OutboundIcon from "@mui/icons-material/Outbound";
import DocumentIcon from "@mui/icons-material/Description";
import { IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import loadingGif from "../../../../assets/images/aws/loadingGif.gif";

import { enqueueSnackbar } from "notistack";

import {
  getReleaseDetailsRequest,
  clearAllReleaseMessages,
  updateOrgAccount,
  resetOrgAccountStatus,
  // getListOfOrgsAndAccts,
  getListOfAccts,
  clearAllOrgAccountMessages,
} from "../../../../store/actions";
import {
  postFeatureConfiguration,
  clearAllFeatureConfigurationMessages,
} from "../../../../store/featureConfiguration/featureConfigurationSlice";

import UrlDisplayDialog from "../../../../pages/Support/common/UrlDisplayDialog";
import ScriptDisplayDialog from "../../Pricing/ScriptDisplayDialog";
import BucketPolicyModal from "./BucketPolicyModal";

const AdditionalAddons = (props) => {
  const {
    releaseDetails,
    isReleaseDetailsGetting,
    getReleaseDetails,
    clearAllReleaseMessages,
    editAccount,
  } = props;
  console.log("AdditionalAddons is rerendered");
  console.log("Additional Addons ::::: ", props);

  const evInsManualConfigGuideLink =
    process.env.REACT_APP_EVENT_INSIGHT_MANUAL_CONFIGURATION_GUIDE;

  console.log("Manual Link :: ", evInsManualConfigGuideLink);
  const coInsManualConfigGuideLink =
    process.env.REACT_APP_COST_INSIGHT_MANUAL_CONFIGURATION_GUIDE;
  const coOptManualConfigGuideLink =
    process.env.REACT_APP_COST_OPTIMIZATION_MANUAL_CONFIGURATION_GUIDE;

  const evInsCliGuideLink = process.env.REACT_APP_EVENT_INSIGHT_CLI_GUIDE;
  const evInsCliScriptLink = process.env.REACT_APP_EVENT_INSIGHT_CLI_SCRIPT;

  const evInsCloudFormationGuideLink =
    process.env.REACT_APP_EVENT_INSIGHT_CLOUDFORMATION_GUIDE;
  const evInsCloudFormationTemplateLink =
    process.env.REACT_APP_EVENT_INSIGHT_CLOUDFORMATION_TEMPLATE;

  const costInsightCloudFormationTemplateLink =
    process.env.REACT_APP_COST_INSIGHT_CLOUDFORMATION_TEMPLATE;
  const costInsightCloudFormationGuideLink =
    process.env.REACT_APP_COST_INSIGHT_CLOUDFORMATION_GUIDE;

  const resourceExplorerGuideLink =
    process.env.REACT_APP_RESOURCE_EXPLORER_MANUAL_GUIDE;

  const [modalType, setModalType] = useState("");
  const [activeSection, setActiveSection] = useState("");
  const [activePart, setActivePart] = useState("");
  const [helpModalOpen, setHelpModalOpen] = useState(false);
  const [scriptModalOpen, setScriptModalOpen] = useState(false);
  const [loadingAddon, setLoadingAddon] = useState("");
  // const [addonRows, setAddonRows] = useState([]);

  const [isBucketPolicyModalOpen, setBucketPolicyModalOpen] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [addOnsName, setAddOnsName] = useState(null);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [isNotificationSent, setNotificationSent] = useState(false);

  const closeBucketPolicyModal = () => {
    setBucketPolicyModalOpen(false);
  };

  const desiredOrder = ["Event Insight", "Cost Insight", "Cost Optimization"];
  
  



  const linksInfo = [
    {
      sectionName: "Event Insight",
      manualLink: evInsManualConfigGuideLink,
      cliGuideLink: evInsCliGuideLink,
      cliScriptLink: evInsCliScriptLink,
      cloudFormationGuideLink: evInsCloudFormationGuideLink,
      cloudFormationTemplateLink: evInsCloudFormationTemplateLink,
    },
    {
      sectionName: "Cost Insight",
      manualLink: coInsManualConfigGuideLink,
      cliGuideLink: null,
      cliScriptLink: null,
      cloudFormationGuideLink: costInsightCloudFormationGuideLink,
      cloudFormationTemplateLink: costInsightCloudFormationTemplateLink,
    },
    {
      sectionName: "Cost Optimization",
      manualLink: coOptManualConfigGuideLink,
      cliGuideLink: null,
      cliScriptLink: null,
      cloudFormationGuideLink: null,
      cloudFormationTemplateLink: null,
    },
  ];

  const renderInfoIcon = (rowData) => {
    // console.log("rowData = ",rowData);
    const value = rowData.value;
    if (value === 0 && rowData.row.addons === "Cost Insight") {
      return "Free";
    } else {
      return value === "Contact us"
        ? value
        : value === 0
        ? "Free"
        : `$${value}`;
    }
  };

  const handleToggle = (id, action) => {
    const selectedAddOns = additionalAddonsRows.find(
      (addon) => addon.id === id
    );
    console.log("selectedAddOns = ", selectedAddOns);
    console.log("handleToggle id = ", id);
    if (action === "Enable") {
      enableAddOn(selectedAddOns?.name);
    } else if (action === "Disable") {
      disableAddOn(selectedAddOns?.name);
    }
    // setAddonRows((prev) =>
    //   prev.map((row) =>
    //     row.id === id ? { ...row, enabled: !row.enabled } : row
    //   )
    // );
  };

  const enableAddOn = (addOnsName) => {
    console.log("editAccount = ", editAccount);
    setAddOnsName(addOnsName);
    if (addOnsName === "Cost Optimization") {
      enableCostOptimization(addOnsName);
    } else {
      setBucketPolicyModalOpen(true);
    }
  };

  const enableCostOptimization = (addOnsName) => {
    console.log("Enabling Cost Optimization");
    const featureConfigurationRequest = {
      role: "",
      accountNumber: editAccount?.accountNumber,
      bucketName: "",
      readOnlyRole: editAccount?.iamrole,
      selectedRegion: "",
      module: addOnsName,
      externalId: editAccount?.externalId,
    };
    console.log("featureConfigurationRequest = ", featureConfigurationRequest);
    props.postFeatureConfiguration(featureConfigurationRequest);
  };

  const disableAddOn = (addOnsName) => {
    setAddOnsName(addOnsName);
    setConfirmationOpen(true);
  };

  const handleNo = () => {
    setAddOnsName(null);
    setConfirmationOpen(false);
  };

  const handleYes = () => {
    console.log("handle disabling addons");
    console.log("Disable editAccount = ", editAccount);
    console.log("Disable addOns = ", addOnsName);
    setConfirmationOpen(false);

    const unchangedAddOns = editAccount.subscription.addons
      .filter((addon) => addon.name !== addOnsName)
      .map((addon) => ({
        name: addon.name,
        enabled: addon.enabled,
      }));

    const subscriptionRequest = {
      name: editAccount.subscription.name,
      addons: [...unchangedAddOns, { name: addOnsName, enabled: false }],
    };

    // Do not pass compliance addons when we edit addons
    // const unchangedComplianceAddOns = editAccount
    //   .complianceAddOns.map(addOn => ({name: addOn.name, enabled: addOn.enabled}) );

    let accountUpdateRequest = {
      orgId: editAccount.orgId,
      accountNumber: editAccount.accountNumber,
      accountName: editAccount.accountName,
      accountCode: editAccount.accountCode,
      urlLink: editAccount.urlLink,
      accounttype: editAccount.accounttype,
      address: editAccount.address,
      city: editAccount.city,
      state: editAccount.state,
      zip: editAccount.zip,
      country: editAccount.country,
      email: editAccount.email,
      phonenumber: editAccount.phonenumber,
      eula: editAccount.eula,
      accountResourceGroups: editAccount.accountResourceGroups.map((rg) => ({
        resourcename: rg.resourcename,
      })),
      subscription: subscriptionRequest,
      complianceAddOns: [],
      iamrole: editAccount.iamrole,
      externalId: editAccount.externalId,
    };

    console.log("accountUpdateRequest: ", accountUpdateRequest);
    props.updateOrgAccount(accountUpdateRequest);
  };

  // Memoize all functions coming as props
  const resetOrgAccountStatusCallback = useCallback(() => {
    props.resetOrgAccountStatus();
  });

  const getListOfAccts = useCallback(() => {
    props.getListOfAccts();
  });
  // const clearAllOrgAccountMessages = useCallback(() => {props.clearAllOrgAccountMessages();});

  const clearAllFeatureConfigurationMessagesCallback = useCallback(() => {
    props.clearAllFeatureConfigurationMessages();
  });

  const updateMessage = props.updateAccountMessage;
  useEffect(() => {
    console.log("useEffect for updateMessage:", updateMessage);
    if (updateMessage) {
      enqueueSnackbar(updateMessage, { variant: "success" });
      console.log("useEffect for updateMessage: calling resetOrgAccountStatus");
      resetOrgAccountStatusCallback();
      getListOfAccts();
    }
  }, [updateMessage, resetOrgAccountStatusCallback, getListOfAccts]);

  const updateErrorMessage = props.reg_acct_error;
  useEffect(() => {
    console.log("useEffect for updateErrorMessage:", updateErrorMessage);
    if (updateErrorMessage) {
      enqueueSnackbar(updateErrorMessage, { variant: "error" });
      console.log(
        "useEffect for updateErrorMessage: calling resetOrgAccountStatus"
      );
      resetOrgAccountStatusCallback();
    }
  }, [updateErrorMessage, resetOrgAccountStatusCallback]);

  // Handling Message/Error of Enabling Feature
  const postFeatureConfigurationMessage = props.postFeatureConfigurationMessage;
  const postFeatureConfigurationError = props.postFeatureConfigurationError;
  useEffect(() => {
    console.log(
      "useEffect for postFeatureConfigurationMessage:",
      postFeatureConfigurationMessage
    );
    if (postFeatureConfigurationMessage && !isNotificationSent) {
      setBucketPolicyModalOpen(false);
      console.log("Calling enqueSnackbar for postFeatureConfigurationMessage");
      enqueueSnackbar(postFeatureConfigurationMessage, { variant: "success" });
      setNotificationSent(true);
      console.log(
        "useEffect for postFeatureConfigurationMessage: calling clearAllFeatureConfigurationMessages"
      );
      clearAllFeatureConfigurationMessagesCallback();
      getListOfAccts();
    }
  }, [
    postFeatureConfigurationMessage,
    isNotificationSent,
    getListOfAccts,
    clearAllFeatureConfigurationMessagesCallback,
  ]);

  useEffect(() => {
    console.log("useEffect for postFeatureConfigurationError:");
    if (postFeatureConfigurationError) {
      enqueueSnackbar(postFeatureConfigurationError, { variant: "error" });
      console.log(
        "useEffect for postFeatureConfigurationMessage: calling clearAllFeatureConfigurationMessages"
      );
      clearAllFeatureConfigurationMessagesCallback();
    }
  }, [
    postFeatureConfigurationError,
    clearAllFeatureConfigurationMessagesCallback,
  ]);

  let additionalAddonsColumns = [
    // { field: "id", headerName: "ID", width: 50, sortable: false },
    {
      field: "addons",
      headerName: "Addons",
      width: 130,
      sortable: false,
      renderCell: (rowData) => {
        // console.log("rowData.field = ", rowData);
        if (rowData.row.name === "Event Insight") {
          return (
            <>
              Event Insight
              <Tooltip
                title="Please contact support if you would like to use encryption for CloudTrail logs."
                placement="top"
              >
                <IconButton size="small" color="primary">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </>
          );
        }
        if (rowData.row.name === "Cost Insight") {
          return <>Cost Insight</>;
        }
        if (rowData.row.name === "Cost Optimization") {
          return <>Cost Optimization</>;
        }
      },
    },

    {
      field: "Configuration",
      headerName: "Manual Configuration",
      width: 250,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        console.log("rowData Manual Config : ", rowData);
        const sectionLinksInfo = linksInfo.find(
          (section) => section.sectionName === rowData.row.name
        );

        if (!sectionLinksInfo) return null;

        if (rowData.row.name === "Cost Optimization") {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "10px", // Space between buttons
                justifyContent: "center", // Center the buttons
                alignItems: "center",
              }}
            >
              {sectionLinksInfo.manualLink ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalType("help");
                    setActiveSection(sectionLinksInfo.sectionName);
                    setActivePart("manual");
                    setHelpModalOpen(true);
                    const config = sectionLinksInfo.manualLink;
                    getReleaseDetails(config);
                  }}
                  style={{ cursor: "pointer" }}
                  disabled={isReleaseDetailsGetting}
                >
                  {activeSection === rowData.row.name &&
                  activePart === "manual" &&
                  isReleaseDetailsGetting ? (
                    <img src={loadingGif} height={20} alt="Loading Spinner" />
                  ) : (
                    <DocumentIcon fontSize="small" color="primary" />
                  )}
                </IconButton>
              ) : null}
              {/* Button 1 */}
              <Tooltip title="Cost Optimization Hub" arrow>
                <IconButton
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={() =>
                    window.open(
                      "https://us-east-1.console.aws.amazon.com/costmanagement/home?region=us-east-1#/cost-optimization-hub/enrollment",
                      "_blank"
                    )
                  }
                >
                  <OutboundIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>

              {/* Button 2 */}
              <Tooltip title="Compute Optimizer" arrow>
                <IconButton
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={() =>
                    window.open(
                      "https://us-east-1.console.aws.amazon.com/compute-optimizer/home?region=us-east-1#/",
                      "_blank"
                    )
                  }
                >
                  <OutboundIcon fontSize="small" color="primary" />
                </IconButton>
              </Tooltip>
            </Box>
          );
        } else {
          return (
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              {sectionLinksInfo.manualLink ? (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    setModalType("help");
                    setActiveSection(sectionLinksInfo.sectionName);
                    setActivePart("manual");
                    setHelpModalOpen(true);
                    const config = sectionLinksInfo.manualLink;
                    getReleaseDetails(config);
                  }}
                  style={{ cursor: "pointer" }}
                  disabled={isReleaseDetailsGetting}
                >
                  {activeSection === rowData.row.name &&
                  activePart === "manual" &&
                  isReleaseDetailsGetting ? (
                    <img src={loadingGif} height={20} alt="Loading Spinner" />
                  ) : (
                    <DocumentIcon fontSize="small" color="primary" />
                  )}
                </IconButton>
              ) : null}
            </div>
          );
        }
      },
    },
    // {
    //   field: "cli",
    //   headerName: "CLI",
    //   width: 100,
    //   sortable: false,
    //   align: "center",
    //   headerAlign: "center",
    //   renderCell: (rowData) => {
    //     // console.log("rowData : ", rowData);
    //     const sectionLinksInfo = linksInfo.find(
    //       (section) => section.sectionName === rowData.row.name
    //     );

    //     if (!sectionLinksInfo) return null;

    //     return (
    //       <div
    //         style={{
    //           // backgroundColor: "lightskyblue",
    //           width: "100%",
    //           textAlign: "center",
    //         }}
    //       >
    //         {sectionLinksInfo.cliGuideLink && (
    //           <IconButton
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setModalType("help");
    //               setActiveSection(sectionLinksInfo.sectionName);
    //               setActivePart("cli-help");
    //               setHelpModalOpen(true);
    //               getReleaseDetails(sectionLinksInfo.cliGuideLink);
    //             }}
    //             style={{ cursor: "pointer" }}
    //             disabled={isReleaseDetailsGetting}
    //           >
    //             {activeSection === rowData.row.name &&
    //             activePart === "cli-help" &&
    //             isReleaseDetailsGetting ? (
    //               <img src={loadingGif} height={20} alt="Loading Spinner" />
    //             ) : (
    //               <DocumentIcon fontSize="small" color="primary" />
    //             )}
    //           </IconButton>
    //         )}
    //         {sectionLinksInfo.cliScriptLink && (
    //           <IconButton
    //             onClick={(e) => {
    //               e.stopPropagation();
    //               setModalType("script");
    //               setActiveSection(sectionLinksInfo.sectionName);
    //               setActivePart("cli-script");
    //               setScriptModalOpen(true);
    //               getReleaseDetails(sectionLinksInfo.cliScriptLink);
    //             }}
    //             style={{ cursor: "pointer" }}
    //             disabled={isReleaseDetailsGetting}
    //           >
    //             {activeSection === rowData.row.name &&
    //             activePart === "cli-script" &&
    //             isReleaseDetailsGetting ? (
    //               <img src={loadingGif} height={20} alt="Loading Spinner" />
    //             ) : (
    //               <OutboundIcon fontSize="small" color="primary" />
    //             )}
    //           </IconButton>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "cloudformation",
      headerName: "Cloudformation",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        // console.log("rowData : ", rowData);
        const sectionLinksInfo = linksInfo.find(
          (section) => section.sectionName === rowData.row.name
        );
        if (!sectionLinksInfo) return null;

        return (
          <div
            style={{
              // backgroundColor: "lightskyblue",
              width: "100%",
              textAlign: "center",
            }}
          >
            {sectionLinksInfo.cloudFormationGuideLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("help");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cf-help");
                  setHelpModalOpen(true);
                  getReleaseDetails(sectionLinksInfo.cloudFormationGuideLink);
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {activeSection === rowData.row.name &&
                activePart === "cf-help" &&
                isReleaseDetailsGetting ? (
                  <img src={loadingGif} height={20} alt="Loading Spinner" />
                ) : (
                  <DocumentIcon fontSize="small" color="primary" />
                )}
              </IconButton>
            )}
            {sectionLinksInfo.cloudFormationTemplateLink && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setModalType("external");
                  setActiveSection(sectionLinksInfo.sectionName);
                  setActivePart("cf-link");
                  getReleaseDetails(
                    sectionLinksInfo.cloudFormationTemplateLink
                  );
                }}
                style={{ cursor: "pointer" }}
                disabled={isReleaseDetailsGetting}
              >
                {activeSection === rowData.row.name &&
                activePart === "cf-link" &&
                isReleaseDetailsGetting ? (
                  <img src={loadingGif} height={20} alt="Loading Spinner" />
                ) : (
                  <OutboundIcon fontSize="small" color="primary" />
                )}
              </IconButton>
            )}
          </div>
        );
      },
    },
    {
      field: "spacer",
      headerName: "", // No header for this column
      flex: 1, // This column will expand and push "Action" to the right
      sortable: false,
      disableColumnMenu: true, // Disable sorting and menu
      renderCell: () => null, // No content in cells
    },
    {
      field: "action",
      headerName: "Action",
      width: 150,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (rowData) => {
        const { name } = rowData.row;
        const isDisabled =
          (name === "Cost Insight" && !props.editAccount.costInsight_status) ||
          (name === "Cost Optimization" && !props.editAccount.costOptimization_status) ||
          (name === "Event Insight" && !props.editAccount.eventInsight_status);

        return (
          <Button
          variant="contained"
          disabled={
            props.updateAccountLoading ||
            props.isPostingFeatureConfiguration ||
            props.getOrgAcctsLoading || isDisabled
          }
          sx={{
            backgroundColor: rowData.row.enabled ? "#f44336" : "#1976d2", // Red for "Disable", Blue for "Enable"
            color: "#fff", // White text color
            "&:hover": {
              backgroundColor: rowData.row.enabled ? "#d32f2f" : "#1565c0", // Darker shades on hover
            },
          }}
          onClick={() => {
            setLoadingAddon(rowData.row.name);
            handleToggle(
              rowData.row.id,
              rowData.row.enabled ? "Disable" : "Enable"
            );
          }}
        >
          {loadingAddon === rowData.row.name &&
          (props.updateAccountLoading ||
            props.isPostingFeatureConfiguration ||
            props.getOrgAcctsLoading) ? (
            <img src={loadingGif} height={20} alt="Loading Spinner" />
          ) : rowData.row.enabled ? (
            "Disable"
          ) : (
            "Enable"
          )}
        </Button>
        )
      },
    },
  ];

  if (props.orgLicensing.data.licenseName === "AwsMarketPlaceLicense") {
    additionalAddonsColumns = additionalAddonsColumns.filter(
      (column) => column.field !== "price"
    );
  }

  let additionalAddonsRows = [];
  let sortedAddOns = [];
  sortedAddOns = [...props.addOns].sort(
    (a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)
  );
  if (props.addOns) {
    additionalAddonsRows = sortedAddOns.map(
      (addOn, index) => (
        console.log("Addons :: ", addOn),
        {
          id: index + 1, // Ensure each row has a unique id
          name: addOn.name,
          enabled: addOn.enabled,
          price: addOn.price,
        }
      )
    );
  }

  useEffect(() => {
    console.log("additionalAddonsRows: ", additionalAddonsRows);
    const alreadySelectedRows = additionalAddonsRows
      .filter((addOn) => addOn.enabled)
      .map((addOn) => addOn.id);
    if (
      rowSelectionModel &&
      rowSelectionModel?.length !== alreadySelectedRows?.length
    ) {
      setRowSelectionModel([...alreadySelectedRows]);
    }
    console.log("alreadySelectedRows: ", alreadySelectedRows);
  }, [additionalAddonsRows, rowSelectionModel]);

  const url = releaseDetails;
  useEffect(() => {
    if (url && modalType && url && modalType === "external") {
      const urlResponse = `https://console.aws.amazon.com/cloudformation/home#/stacks/create/review?templateURL=${encodeURIComponent(
        url
      )}`;
      // console.log("urlResponse = ", urlResponse);
      window.open(urlResponse);
    }
  }, [url, modalType]);

  return (
    <Box sx={{ width: "100%" }}>
      <Alert variant="outlined" severity="info" icon={false}>
        Please select any Additional Services and configure them through any of
        the available options below.
      </Alert>
      <DataGrid
        rows={additionalAddonsRows}
        columns={additionalAddonsColumns}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        checkboxSelection
        rowSelectionModel={rowSelectionModel}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        autoHeight
        rowHeight={40}
        getRowClassName={(params) => {
          const { name } = params.row;
          if (
            (name === "Cost Insight" &&
              !props.editAccount.costInsight_status) ||
            (name === "Cost Optimization" &&
              !props.editAccount.costOptimization_status) ||
            (name === "Event Insight" && !props.editAccount.eventInsight_status)
          ) {
            return "blurred-row"; // Apply this class to rows that should be blurred
          }
          return "";
        }}
        sx={{
          "& .blurred-row": {
            opacity: 0.5, // Make rows appear blurred
            pointerEvents: "none", // Disable interactions on these rows
          },
          "& .MuiDataGrid-checkboxInput": {
            pointerEvents: "none", // Disable interaction with checkboxes
          },
        }}
      />

      {helpModalOpen && releaseDetails && (
        <UrlDisplayDialog
          url={releaseDetails}
          pageTitle="Help"
          isOpen={helpModalOpen}
          setOpen={setHelpModalOpen}
          clearUrl={clearAllReleaseMessages}
        />
      )}
      {scriptModalOpen && releaseDetails && (
        <ScriptDisplayDialog
          url={releaseDetails}
          pageTitle="Help"
          isOpen={scriptModalOpen}
          setOpen={setScriptModalOpen}
          clearUrl={clearAllReleaseMessages}
        />
      )}
      {/* Modal for Bucket Policy Verification */}
      {isBucketPolicyModalOpen && addOnsName && (
        <BucketPolicyModal
          isOpen={isBucketPolicyModalOpen}
          closeModal={closeBucketPolicyModal}
          addOnsName={addOnsName}
          editAccount={editAccount}
          setNotificationSent={setNotificationSent}
        />
      )}

      {/* Modal for confirmation of Disabling  addon*/}
      {isConfirmationOpen && (
        <Modal
          isOpen={isConfirmationOpen}
          backdrop="static"
          centered
          style={{ maxWidth: "350px" }}
        >
          <ModalHeader toggle={props.closeModal}>
            Confirmation for Disabling Addon
          </ModalHeader>
          <ModalBody>Are you sure you want to disable addon?</ModalBody>
          <ModalFooter>
            <div
              style={{ display: "flex", gap: "20px", alignItems: "flex-end" }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#1976d2",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
                onClick={handleYes}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#f44336",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#d32f2f",
                  },
                }}
                onClick={handleNo}
              >
                No
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </Box>
  );
};

const mapStateToProps = (state) => {
  const {
    accounts,
    organizations,
    reg_acct_error,
    account,
    loading: updateAccountLoading,
    message: updateAccountMessage,
  } = state.orgAccount;

  const {
    loading: getOrgAcctsLoading,
    message: getOrgAcctsMessage,
    error: getOrgAcctsError,
  } = state.acctList;
  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  const {
    isPostingFeatureConfiguration,
    postFeatureConfigurationMessage,
    postFeatureConfigurationError,
  } = state.featureConfiguration;

  return {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,

    accounts,
    organizations,
    reg_acct_error,
    account,
    updateAccountLoading,
    updateAccountMessage,
    orgLicensing: state.licensing,

    getOrgAcctsLoading,
    getOrgAcctsMessage,
    getOrgAcctsError,

    isPostingFeatureConfiguration,
    postFeatureConfigurationMessage,
    postFeatureConfigurationError,

    orgLicensing: state.licensing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getReleaseDetails: (fileName) => {
      dispatch(getReleaseDetailsRequest(fileName));
    },
    clearAllReleaseMessages: () => {
      dispatch(clearAllReleaseMessages());
    },
    // getListOfOrgsAndAccts: () => {
    //   dispatch(getListOfOrgsAndAccts());
    // },
    getListOfAccts: () => {
      dispatch(getListOfAccts());
    },
    updateOrgAccount: (data) => {
      dispatch(updateOrgAccount(data));
    },
    // clearAllOrgAccountMessages: () => {
    //   dispatch(clearAllOrgAccountMessages());
    // },
    resetOrgAccountStatus: () => {
      dispatch(resetOrgAccountStatus(null));
    },
    postFeatureConfiguration: (featureConfigurationPayload) => {
      dispatch(postFeatureConfiguration(featureConfigurationPayload));
    },
    clearAllFeatureConfigurationMessages: () => {
      dispatch(clearAllFeatureConfigurationMessages());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalAddons);
