import React from "react";
import { Typography } from "@mui/material";

// Function to determine color based on value
const getColorByValue = (value, type) => {
  if (type === "risk") {
    switch (value) {
      case "UNANSWERED":
        return "black";
      case "HIGH":
        return "red";
      case "MEDIUM":
        return "orange";
      case "LOW":
        return "blue";
      default:
        return "gray";
    }
  } else if (type === "reason") {
    return "green"; 
  }
};

const RiskReasonDisplay = ({ label, value, type }) => {
  if (!value) return null; 
  return (
    <Typography
      component="span"
      sx={{
        fontSize: "0.75rem",
      }}
    >
      <span style={{ color: "black" }}>{label}: </span>
      <span style={{ color: getColorByValue(value, type) }}>{value}</span>
    </Typography>
  );
};

export default RiskReasonDisplay;
