import React from "react";
import { Box, Divider, Typography } from "@mui/material";
import RiskReasonDisplay from "./RiskReasonDisplay";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const SectionQuestionsPanel = ({
  sectionQuestionLabel,
  sectionQuestionList,
  selectedQuestion,
  setSelectedQuestion,
}) => {
  return (
    <Box sx={{ overflowY: "auto" }}>
      {sectionQuestionList.map((que, index) => (
        <Box
          key={index}
          sx={{
            backgroundColor: selectedQuestion === index ? "#F0F0F0" : "white",
            padding: "0.5rem",
            cursor: "pointer",
            display: "flex",
            alignItems: "flex-start",
            "&:hover": { backgroundColor: "#F9F9F9" },
          }}
          onClick={() => setSelectedQuestion(index)} // Call setSelectedQuestion on click
        >
          <Box
            sx={{
              width: "24px", // Fixed width to reserve space for the tick mark
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {que.risk !== "UNANSWERED" 
              ? (<CheckBoxIcon sx={{ fontSize: "1rem", color: "green" }} />)
              : (<CheckBoxOutlineBlankIcon sx={{ fontSize: "1rem", color: "green" }} />)
            }
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography component="p" fontSize="0.75rem">
              {`${sectionQuestionLabel} ${index + 1}.`} {que.questionTitle}
            </Typography>
            {/* Risk and Reason */}
            <Typography component="p" fontSize="0.75rem">
              <RiskReasonDisplay label="Risk" value={que.risk} type="risk" />
              {que.risk && que.reason ? " | ": null}
              <RiskReasonDisplay label="Reason" value={que.reason} type="reason" />
            </Typography>
          </Box>
          <Divider sx={{ marginTop: "0.5rem" }} />
        </Box>
      ))}
    </Box>
  );
};

export default SectionQuestionsPanel;
