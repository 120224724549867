import {
  ADD_ORGANIZATION,
  ADD_ORGANIZATION_SUCCESS,
  ADD_ORGANIZATION_FAILURE,
  REGISTER_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT,
  UPDATE_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_SUCCESSFUL,
  REGISTER_ORG_ACCOUNT_FAILED,
  UPDATE_ORG_ACCOUNT_FAILED,
  GET_LIST_OF_ORGS_AND_ACCOUNTS,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL,
  GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED,
  RESET_ORG_ACCOUNT_STATUS,
  CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL,
} from "./actionTypes";

const initialState = {
  addOrganizationError: null,
  isAddingOrganization: false,
  addOrganizationMessage: null,
  reg_acct_error: null,
  accountsError: null,
  message: null,
  loading: null,
  error: false,
  fetchFailed: false,
  organizations: [],
  registerOrgAccountMessage: null,
  isRegisteringOrgAccount: false,
};

const orgAccount = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ORGANIZATION:
      console.log(
        "reducer -> ADD_ORGANIZATION called with organization: ",
        action.payload.organization
      );
      state = {
        ...state,
        isAddingOrganization: true,
        addOrganizationError: null,
        organization: action.payload.organization,
        addOrganizationMessage: null,
      };
      break;
    case ADD_ORGANIZATION_SUCCESS:
      console.log(
        "reducer -> ADD_ORGANIZATION_SUCCESS called with organization: ",
        action.payload.organization
      );
      state = {
        ...state,
        isAddingOrganization: false,
        addOrganizationError: null,
        organization: action.payload.organization,
        addOrganizationMessage: action.payload.organization.message,
      };
      break;
    case ADD_ORGANIZATION_FAILURE:
      console.log(
        "reducer -> ADD_ORGANIZATION_FAILURE called with error: ",
        action.payload.error
      );
      state = {
        ...state,
        isAddingOrganization: false,
        addOrganizationError: action.payload.error,
        organization: null,
      };
      break;
    case REGISTER_ORG_ACCOUNT:
      state = {
        ...state,
        account: null,
        // loading: true,
        reg_acct_error: null,
        // error: false,
        // message: null,
        registerOrgAccountMessage: null,
        isRegisteringOrgAccount: true,
      };
      break;
    case REGISTER_ORG_ACCOUNT_SUCCESSFUL:
      state = {
        ...state,
        // message: action.payload?.registerOrgAccountResponse?.message,
        registerOrgAccountMessage:
          action.payload?.registerOrgAccountResponse?.message,
        // loading: false,
        // reg_acct_error: null,
        isRegisteringOrgAccount: false,
      };
      break;
    case REGISTER_ORG_ACCOUNT_FAILED:
      // console.log("payload is", action.payload)
      state = {
        ...state,
        // loading: false,
        reg_acct_error: action.payload
          ? action.payload.registerOrgAccountError.message
          : null,
        // error: true,
        isRegisteringOrgAccount: false,
      };
      break;
    case UPDATE_ORG_ACCOUNT:
      state = {
        ...state,
        // accounts: action.payload.account,
        // account: null,
        loading: true,
        message: null,
        reg_acct_error: null,
      };
      break;
    case UPDATE_ORG_ACCOUNT_SUCCESSFUL:
      state = {
        ...state,
        // ...action.payload.accounts,
        // account: action.payload,
        // accounts: action.payload.account,
        loading: false,
        message: action.payload?.updateOrgAccountResponse?.message,
        reg_acct_error: null,
      };
      break;
    case UPDATE_ORG_ACCOUNT_FAILED:
      // console.log("payload is", action.payload)
      state = {
        ...state,
        loading: false,
        reg_acct_error: action.payload
          ? action.payload.updateOrgAccountError.message
          : null,
        // error: true,
      };
      break;
    case RESET_ORG_ACCOUNT_STATUS:
      state = {
        ...state,
        account: action.payload,
        loading: false,
        reg_acct_error: null,
        message: null,
        registerOrgAccountMessage: null,
        isRegisteringOrgAccount: false,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS:
      state = {
        ...state,
        accounts: null,
        message: null,
        loading: true,
        accountsError: null,
        fetchFailed: false,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL:
      state = {
        ...state,
        ...action.payload.accounts,
        ...action.payload.usersConfig,
        loading: false,
        accountsError: null,
      };
      break;
    case GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED:
      state = {
        ...state,
        loading: false,
        accountsError: action.payload.getListOfOrgsAndAcctsError.message,
        fetchFailed: true,
      };
      break;
    case CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL:
      state = {
        ...state,
        reg_acct_error: null,
        accountsError: null,
        message: null,
        loading: false,
        error: false,
      };
      break;
    default:
      return state;
  }
  return state;
};

export default orgAccount;
