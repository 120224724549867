import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { sidebarMenuOptions } from "./SidebarMenuOptions";
import { getProfileRoleInfo } from "../../store/actions";
import DirtyRedirectCheckHoc from "./../../routes/dirtyRedirectCheckHoc";
import { Box, Typography } from "@mui/material";

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const [sideMenuOptions, setSideMenuOptions] = useState([]);
  const { profile } = useSelector((state) => state);
  const depth = 0;
  const depthStep = 10;

  const makeExpandAll = (menus) => {
    menus.forEach((subMenu) => {
      if (!subMenu.hasOwnProperty("expand")) {
        return;
      }
      if (subMenu.hasOwnProperty("expand")) {
        subMenu.expand = true;
      }
      if (subMenu.subMenu) {
        makeExpandAll(subMenu.subMenu);
      }
    });
    return menus;
  };

  const handleRedirectLink = (e, item = {}) => {
    const isDirty = sessionStorage.getItem("isDirty", "true");
    const targetLink = item.link;
    if (targetLink && !isDirty) {
      e.preventDefault();
      e.stopPropagation();
      history.push(targetLink, { id: item.id, params: item.params });
    }
  };

  const toggleExpandFlag = (menus, item) => {
    menus.forEach((subMenu) => {
      if (subMenu.link === item.link && subMenu.hasOwnProperty("expand")) {
        subMenu.expand = !subMenu.expand;
        //                console.log("Flag flipped")
        return subMenu;
      } else if (subMenu.subMenu) {
        //                console.log("Going in submenu")
        return toggleExpandFlag(subMenu.subMenu, item);
      } else {
        //                console.log("returning as it is")
        return subMenu;
      }
    });
    return menus;
  };

  const toggleSubmenuExpand = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log("Inside toggelSubmentuExpand")
    // console.log(item)
    if (item.hasOwnProperty("subMenu")) {
      // console.log("Inside modifying flag")
      const menus = [...sideMenuOptions];
      // console.log("Menu before toggle")
      // console.log(menus)
      const modifiedMenu = toggleExpandFlag(menus, item);
      // console.log("Menu after toggle")
      // console.log(modifiedMenu)
      setSideMenuOptions(modifiedMenu);
    }
  };

  const nodeToRootPath = (node, item) => {
    if (node.link === item.link) {
      // console.log("Match found menuChild.link = "+ node.link+ " and item.link = "+item.link)
      const list = [];
      list.push(node);
      return list;
    } else if (node.subMenu) {
      for (const menuItem of node.subMenu) {
        // console.log("Going in submenu "+menuItem.displayName)
        let pathTillClickedMenuItem = nodeToRootPath(menuItem, item);
        if (!pathTillClickedMenuItem) return [];
        else if (pathTillClickedMenuItem.length > 0) {
          pathTillClickedMenuItem.push(node);
          return pathTillClickedMenuItem;
        }
      }
    }
    return [];
  };

  const searchPath = (menus, item) => {
    const pathResult = [];
    let matchPath;
    for (const menuItem of menus) {
      pathResult.push(nodeToRootPath(menuItem, item));
    }
    for (const path of pathResult) {
      if (path.length > 0) {
        matchPath = path;
        break;
      }
    }
    if (!matchPath) {
      item.link = item.link.split("/").slice(0, -1).join("/") + "/";
      const pathResult = [];
      for (const menuItem of menus) {
        pathResult.push(nodeToRootPath(menuItem, item));
      }
      for (const path of pathResult) {
        if (path.length > 0) {
          matchPath = path;
          break;
        }
      }
    }
    return matchPath;
  };

  const makeLinkActive = (e, item) => {
    const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
    console.log("==== Menu before making active");
    console.log("menus = ", menus);
    console.log("item = ", item);
    const matchPath = searchPath(menus, item);
    console.log("matchPath : ", matchPath);
    if (matchPath) {
      for (const pathItem of matchPath) {
        if (pathItem.subMenu) {
          pathItem.expand = true;
        } else {
          pathItem.isActive = true;
        }
      }
    }
    // console.log("==== Menu after making active")
    // console.log(menus)
    setSideMenuOptions(menus);

    // To handle rerender and whole page load
    sessionStorage.setItem("menus", JSON.stringify(menus));
  };
  
  useEffect(() => {
    if (!profile?.roleInfo && !profile.loading) {
      console.log("Calling getProfileRoleInfo() from SidebarContent#useEffect");
      dispatch(getProfileRoleInfo());
    }
  }, []);

  useEffect(() => {
    // console.log(">============ inside useEffect")
    const menusAsString = sessionStorage.getItem("menus");
    // console.log('$$$$$$$$$$$$$$$$$$$ Menus from storage', menusAsString)
    if (menusAsString != null) {
      const menus = JSON.parse(menusAsString);
      if (!props.menuType) {
        // console.log("~~~~~ setting menu for expand true");
        const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
        const menuOptionsExpand = makeExpandAll(menus);
        setSideMenuOptions(menuOptionsExpand);
      } else {
        setSideMenuOptions(menus);
      }
    } else {
      if (!props.menuType) {
        // console.log("~~~~~ setting menu for expand true");
        const menus = sidebarMenuOptions(props.t, handleRedirectLink, profile);
        const menuOptionsExpand = makeExpandAll(menus);
        setSideMenuOptions(menuOptionsExpand);
      } else {
        const menuOptions = sidebarMenuOptions(
          props.t,
          handleRedirectLink,
          profile
        );
        setSideMenuOptions(menuOptions);
      }
    }
    // console.log('####### location.pathname = ', location.pathname);
    if (props.menuType) {
      const bareLinkLinkObj = {
        link: location.pathname,
      };
      // console.log("####### bare link object = ", bareLinkLinkObj);
      makeLinkActive(undefined, bareLinkLinkObj);
    }
  }, [profile]); //location removed

  function SidebarItem({ item, depthStep = 10, depth = 0, ...rest }) {
    if (!item.isVisible) return null;

    return (
      <li
        className={item.isActive ? "mm-active" : ""}
        key={new Date().getTime() + item.id}
      >
        <Link
          link={item.link}
          to="#"
          onClick={
            item.subMenu
              ? (e) => toggleSubmenuExpand(e, item)
              : (e) => {
                  makeLinkActive(e, item);
                  e.target.link = item.link;
                  handleRedirectLink(e, item);
                }
          }
          group={item.group}
          className={`${item.classNames} ${item.isActive ? "active" : ""}`}
        >
          <Box className="d-flex flex-row justify-content-between">
            <Box>
              <Typography variant="body2">
                <i className={item.iconClassName}></i>
                <span className="ml-1">{item.displayName}</span>
              </Typography>
            </Box>
            {item.subMenu && (
              <i
                className={
                  item.expand ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                }
              ></i>
            )}
          </Box>
        </Link>

        {item.expand && item.subMenu && (
          <ul
            className="metismenu list-unstyled pl-4"
            id="side-menu"
            style={{ backgroundColor: "#e6e6e6" }}
          >
            {item.subMenu.map((entity, index) => (
              <SidebarItem
                key={`${entity.displayName}${index}`}
                depthStep={depthStep}
                depth={depth}
                item={entity}
              />
            ))}
          </ul>
        )}
      </li>
    );
  }

  return (
    <React.Fragment>
      <DirtyRedirectCheckHoc isParentElement={true}>
        <Box id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            {sideMenuOptions &&
              sideMenuOptions.map((item, index) => (
                <SidebarItem
                  key={`${item.displayName}${index}`}
                  depthStep={depthStep}
                  depth={depth}
                  item={item}
                />
              ))}
          </ul>
        </Box>
      </DirtyRedirectCheckHoc>
    </React.Fragment>
  );
};

export default withNamespaces()(SidebarContent);
