import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";

const CISReports = () => {
  const [activeTab, setActiveTab] = useState("auditor");

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "reports":
        return <GetKibanaDashboard dashboardKey={"cis_waf_Report"} />;
      case "auditor":
        return <GetKibanaDashboard dashboardKey={"cis_waf_auditor"} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="Summary" value="auditor" />
        <Tab label="Details" value="reports" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default CISReports;
