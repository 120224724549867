import React, { useState, useEffect } from "react";
import SecurityScore from "./SecurityScore";
import Compliance from "./Compliance";
import AccountSummary from "./AccountsSummary";
import AssetInsightCard from "./AssetInsight";
import CostInsight from "./CostInsight";
import EventInsight from "./EventInsight"; // Importing the EventInsight component
import { ResponsiveGridLayout } from "./components/ResponsiveGridLayout";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import loadingGif from "./../../assets/images/aws/loadingGif.gif";
import { getLicensingData } from "../../store/actions";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import { useAccounts } from "../../components/Common/HelperComponents/GetDataFromStore";
import CostOptimization from "./CostOptimization";

const DashboardPage = (props) => {
  const accounts = useAccounts();
  const [isAccountConfigured, setIsAccountConfigured] = useState(null);
  const [accountStatus, setAccountStatus] = useState(null);

  // Breakpoints for different screen sizes
  const breakpoints = { lg: 1600, md: 1200, sm: 768, xs: 480, xxs: 0 };
  const cols = { lg: 16, md: 12, sm: 6, xs: 4, xxs: 2 };

  const layouts = {
    lg: [
      { i: "assetInsight", x: 0, y: 0, w: 4, h: 6 },
      { i: "costInsight", x: 12, y: 0, w: 4, h: 1.5 },
      { i: "costOptimization", x: 12, y: 2, w: 4, h: 1.5 },
      { i: "eventInsight", x: 4, y: 6, w: 12, h: 3 },
      { i: "securityScore", x: 4, y: 0, w: 4, h: 3 },
      { i: "compliance", x: 8, y: 0, w: 4, h: 3 },
    ],
    md: [
      { i: "assetInsight", x: 0, y: 0, w: 3, h: 6 },
      { i: "costInsight", x: 12, y: 0, w: 3, h: 1.7 },
      { i: "costOptimization", x: 12, y: 2, w: 3, h: 1.3 },
      { i: "eventInsight", x: 3, y: 6, w: 9, h: 3 },
      { i: "securityScore", x: 3, y: 0, w: 3, h: 3 },
      { i: "compliance", x: 6, y: 0, w: 3, h: 3 },
    ],
    sm: [
      { i: "assetInsight", x: 0, y: 0, w: 6, h: 6 },
      { i: "costInsight", x: 6, y: 6, w: 6, h: 1.5 },
      { i: "costOptimization", x: 6, y: 8, w: 6, h: 1.5 },
      { i: "eventInsight", x: 0, y: 9, w: 6, h: 3 },
      { i: "securityScore", x: 0, y: 12, w: 6, h: 3 },
      { i: "compliance", x: 0, y: 15, w: 6, h: 3 },
    ],
    xs: [
      { i: "assetInsight", x: 0, y: 0, w: 4, h: 6 },
      { i: "costInsight", x: 4, y: 6, w: 4, h: 1.5 },
      { i: "costOptimization", x: 4, y: 8, w: 4, h: 1.5 },
      { i: "eventInsight", x: 0, y: 9, w: 4, h: 3 },
      { i: "securityScore", x: 0, y: 12, w: 4, h: 3 },
      { i: "compliance", x: 0, y: 15, w: 4, h: 3 },
    ],
  };

  let awsAccountConfigEl = null;

  // console.log("~~~~~props.licenseData : ", props.licenseData);
  // console.log("~~~~~isAccountConfigured : ", isAccountConfigured);
  // console.log("~~~~~accountStatus : ", accountStatus);
  const getLicensingData = props.getLicensingData;
  const currentLicense = props.licenseData?.data?.licenseName;
  const currentLicenseLoading = props.licenseData?.loading;
  useEffect(() => {
    if (!currentLicense && !currentLicenseLoading) {
      getLicensingData();
    }
  }, [currentLicense, getLicensingData, currentLicenseLoading]);

  useEffect(() => {
    let accCodes;
    let accountNumbers = [];
    let userInfo;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      userInfo = jwt_decode(obj.idToken).email;
    }

    if (accounts) {
      accCodes = accounts.filter((account) =>
          account.status === "ACTIVE" &&
          account.listusr?.some((user) => user.userName === userInfo)
        ).map((account) => account.accountCode);
      accounts.map((account) => accountNumbers.push(account.accountNumber));
      // console.log("accCodes: ", accCodes);
      if (accCodes.length > 0) {
        if (isAccountConfigured === null) {
          setIsAccountConfigured(true);
          setAccountStatus("ACTIVE");
        }
      } else if (isAccountConfigured === null) {
        setIsAccountConfigured(false);
        const inactiveAccounts = accounts.filter(
          (account) =>
            account.status === "INACTIVE" && account.number !== "INIT00000001"
        );
        if (inactiveAccounts.length > 0) {
          setAccountStatus("INACTIVE");
        } else {
          setAccountStatus("NOACCOUNT");
        }
      }
    }
  }, [accounts, isAccountConfigured]);

  // Generating spinner UI while license data is loading.
  if (currentLicenseLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100vh",
          alignItems: "center",
        }}
      >
        <img src={loadingGif} height={40} alt="Loading Spinner" />
      </div>
    );
  }

  if (props.licenseData?.data?.createdDate !== null) {
    const createdDate = props.licenseData?.data?.createdDate;
    const createdTime =
      new Date(createdDate).getTime() -
      new Date(createdDate).getTimezoneOffset() * 60 * 1000;
    const currentTime = new Date().getTime();
    const oneHourInMillis = 60 * 60 * 1000;
    if (currentTime - createdTime <= oneHourInMillis) {
      return (
        <div className="page-content" style={{ background: "#EAEAEA" }}>
          <InaccessibleResources message="Please check back in an hour as Cloudcatcher is scanning your environment!" />
        </div>
      );
    }
  }
  if (isAccountConfigured !== null && !isAccountConfigured) {
    if (accountStatus === "INACTIVE") {
      awsAccountConfigEl = (
        <div
          style={{
            backgroundColor: "lightgrey",
            fontSize: "16px",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            All the AWS accounts in this organization are inactive. Please add a
            new AWS account or activate the inactive accounts through the{" "}
            <a href="/configuration/accounts" style={{ color: "blue" }}>
              configuration
            </a>{" "}
            menu on the left.
          </div>
        </div>
      );
    } else if (accountStatus === "NOACCOUNT") {
      awsAccountConfigEl = (
        <div
          className="page-content"
          style={{
            backgroundColor: "lightgrey",
            fontSize: "16px",
            display: "flex",
            height: "100vh",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              width: "50%",
              backgroundColor: "white",
              color: "black",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            This organization does not have any AWS account on-boarded. Please
            add an AWS account through{" "}
            <a href="/configuration/accounts" style={{ color: "blue" }}>
              configuration
            </a>{" "}
            menu on the left.
          </div>
        </div>
      );
    }
  }

  if (awsAccountConfigEl) {
    return (
      <div className="page-content" style={{ background: "#EAEAEA" }}>
        {awsAccountConfigEl}
      </div>
    )
  }

  if (currentLicense && accountStatus === "ACTIVE") {
    // Display Dashboard when everything is okay
    return (
      <ResponsiveGridLayout
        className="layout"
        breakpoints={breakpoints}
        cols={cols}
        layouts={layouts}
        rowHeight={100}
        isDraggable={true}
        isResizable={false}
        draggableHandle=".drag-handle"
      >
        <div key="costInsight">
          <CostInsight />
        </div>
        <div key="costOptimization">
          <CostOptimization />
        </div>
        <div key="eventInsight">
          {" "}
          {/* Added EventInsight */}
          <EventInsight />
        </div>
        <div key="securityScore">
          <SecurityScore />
        </div>
        <div key="compliance">
          <Compliance />
        </div>
        <div key="assetInsight">
          <AssetInsightCard />
        </div>
      </ResponsiveGridLayout>
    );
  }    
};

const mapStateToProps = (state) => {
  return {
    licenseData: state.licensing,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getLicensingData: () => {
      dispatch(getLicensingData());
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
