import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  Modal,
  Box,
  Grid,
  TextField,
  Button,
  Typography,
  Autocomplete,
  CircularProgress,
  Snackbar,
  Alert,
  MenuItem,
  Tooltip,
} from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { countryList } from "../../utilities/countries";
import { addOrganization } from "../../store/actions";

const AddOrganizationModal = ({ open, onClose, onSuccess, mspOrgCode }) => {
  const dispatch = useDispatch();
  const { isAddingOrganization, addOrganizationMessage, addOrganizationError } =
    useSelector((state) => state.orgAccount);
  console.log(
    "🚀 ~ AddOrganizationModal ~ isAddingOrganization:",
    isAddingOrganization
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");

  // Form State
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [phonenumberError, setPhonenumberError] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userProvince, setUserProvince] = useState("");
  const [userZipcode, setUserZipcode] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userComments, setUserComments] = useState("");
  const [noOfAccounts, setNoOfAccounts] = useState("");
  const [noOfUsers, setNoOfUsers] = useState("");
  const [registrationType, setRegistrationType] = useState("");

  // Clear form state
  const clearFormState = () => {
    setUserFirstName("");
    setUserLastName("");
    setUserPhone("");
    setPhonenumberError("");
    setUserEmail("");
    setCompanyName("");
    setUserAddress("");
    setUserCity("");
    setUserProvince("");
    setUserZipcode("");
    setUserCountry("");
    setUserComments("");
    setNoOfAccounts("");
    setNoOfUsers("");
    setRegistrationType("");
  };

  // Validation function
  const isFormInvalid = () => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      !userFirstName ||
      !userLastName ||
      !userEmail ||
      !emailRegex.test(userEmail) ||
      !userAddress ||
      !userCity ||
      !userZipcode ||
      !noOfAccounts ||
      !noOfUsers ||
      (userPhone && !isPossiblePhoneNumber(userPhone)) ||
      !registrationType
    );
  };

  // Handle form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!isFormInvalid()) {
      const newOrganization = {
        firstName: userFirstName,
        lastName: userLastName,
        phoneNumber: userPhone,
        email: userEmail,
        company: companyName,
        workAddress: userAddress,
        city: userCity,
        province: userProvince,
        zipcode: userZipcode,
        country: userCountry,
        comments: userComments,
        maxusers: noOfUsers,
        maxacounts: noOfAccounts,
        registrationtype: registrationType,
        mspOrgCode: mspOrgCode,
      };
      console.log("🚀 ~ handleFormSubmit ~ newOrganization:", newOrganization);
      dispatch(addOrganization(newOrganization));
    }
  };

  // Monitor success and error from Redux state
  useEffect(() => {
    if (addOrganizationMessage) {
      setSnackbarMessage(addOrganizationMessage);
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setTimeout(() => {
        onClose();
        clearFormState();
        onSuccess();
      }, 5000); // Notify parent and close modal after 2 seconds
    }

    if (addOrganizationError) {
      setSnackbarMessage(
        addOrganizationError.message || "Failed to add organization."
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  }, [addOrganizationMessage, addOrganizationError, onClose, onSuccess]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Handle phone number validation
  const handlePhoneBlur = () => {
    if (!userPhone || (userPhone && !isPossiblePhoneNumber(userPhone))) {
      setPhonenumberError("Please enter a valid phone number.");
    } else {
      setPhonenumberError("");
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="add-organization-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflowY: "auto",
          maxHeight: "90vh",
        }}
      >
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="userFirstName"
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="userLastName"
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                international
                defaultCountry="US"
                value={userPhone}
                onChange={setUserPhone}
                onBlur={handlePhoneBlur}
                placeholder="Phone Number"
                required
              />
              {phonenumberError && (
                <Typography color="error" variant="body2">
                  {phonenumberError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Work Email"
                name="userEmail"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company Name"
                name="companyName"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                name="registrationType"
                value={registrationType}
                onChange={(e) => setRegistrationType(e.target.value)}
                label="Subscription Type"
                error={!registrationType && snackbarOpen}
                required
              >
                {[
                  {
                    value: "SUBORG",
                    label: "All Features",
                    description: "Full version of Cloudcatcher including WAFR",
                  },
                  {
                    value: "SUBORGWARF",
                    label: "WAFR Only",
                    description: "Only Well Architected Framework Review",
                  },
                ].map(({ value, label, description }) => (
                  <MenuItem key={value} value={value}>
                    <Grid container alignItems="center" spacing={1}>
                      <Grid item>{label}</Grid>
                      <Grid item>
                        <Tooltip title={description} arrow>
                          <InfoIcon fontSize="small" color="action" />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="userAddress"
                value={userAddress}
                onChange={(e) => setUserAddress(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="City"
                name="userCity"
                value={userCity}
                onChange={(e) => setUserCity(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="State / Province"
                name="userProvince"
                value={userProvince}
                onChange={(e) => setUserProvince(e.target.value)}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Zip Code"
                name="userZipcode"
                value={userZipcode}
                onChange={(e) => setUserZipcode(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                fullWidth
                options={countryList}
                getOptionLabel={(option) => option}
                value={userCountry}
                onChange={(event, newValue) => setUserCountry(newValue)}
                renderInput={(params) => (
                  <TextField {...params} label="Country" required />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of AWS Accounts"
                name="noOfAccounts"
                type="number"
                value={noOfAccounts}
                onChange={(e) => setNoOfAccounts(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Number of Users"
                name="noOfUsers"
                type="number"
                value={noOfUsers}
                onChange={(e) => setNoOfUsers(e.target.value)}
                variant="outlined"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Comments (Optional)"
                name="userComments"
                value={userComments}
                onChange={(e) => setUserComments(e.target.value)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Box mt={2}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={isAddingOrganization || isFormInvalid()}
            >
              {isAddingOrganization ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </form>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

AddOrganizationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AddOrganizationModal;
