import React, { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import { Formik } from 'formik';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { countryList } from "../../utilities/countries";
import Select from "react-select";
import { Table, TableHead, TableBody, TableRow, TableCell, Snackbar } from '@mui/material';
import { Alert } from "@material-ui/lab";
import { clearAllUserListMessages } from "./../../store/actions";

import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import IconButton from "@material-ui/core/IconButton";
import EncryptPassword from '../EncryptPassword';
import getUserRole from '../../helpers/jwt-token-access/userRole';
import 'react-phone-number-input/style.css'
import PhoneInput, {parsePhoneNumber, isPossiblePhoneNumber, getCountryCallingCode  } from 'react-phone-number-input';
import { ExtractCountryCallingCode, ExtractPhoneNumber } from './ExtractPhoneNumber';


const AddNewUser = ({ addNewUserResponse, editUserResponse, organizations = {}, usersConfig, accounts, selectedUser, showAdduser, setShowAddUsers, handleOnSubmit }) => {
    // const { data, status } = addNewUserResponse || {};
    const dispatch = useDispatch();
    const { addNewUserMessage, addNewUserLoading, addNewUserError } = addNewUserResponse;
    const { editUserMessage, editUserLoading, editUserError } = editUserResponse;
    const { resourceGroups } = organizations;
    // const [emailDomains, setEmailDomains] = useState("");
    const [formikParams, setFormikParams] = useState();
    const [initValues, setInitValues] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        email: '',
        emailDomain: '',
        country: '',
        phoneNumber: '',
        countryCode: '',
        orgCode: organizations.orgcode,
        acccodes: [],
        userRolesDTOS: [],
        resourceGroupDTOS: []
    });
    const [isEditFlow, setEditFlow] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const userRole = getUserRole();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const validateEmailExistence = (formValues) => {
        // console.log("validateEmailExistence called");
        // console.log("validateEmailExistence called, props:", this.props);
        if(formValues.email && usersConfig) {
            // console.log("validateEmailExistence executed");
            const users  = usersConfig;
            const userExists = users.some(user => user.email.toLowerCase() === formValues.email.toLowerCase()+formValues.emailDomain);
            if(userExists) 
                return "This user already exists.";
            else 
                return null;
        }
    }

    const passwordRegex = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~])[A-Za-z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?`~]{8,}$/;

    // To populate select options for email domain field
    const orgBillingEmail = organizations?.orgbillingemail;
    useEffect(() => {
        // console.log("organizations = ", organizations);
        // console.log("################################ orgBillingEmail = ", orgBillingEmail);
        if(orgBillingEmail){
            // setEmailDomains( [...new Set(["@"+orgBillingEmail.split("@").pop(),"@cloudnovatech.com"])] );
            setInitValues({...initValues, emailDomain: "@"+orgBillingEmail.split("@").pop()});
        }
    }, [orgBillingEmail]);

    useEffect(() => {
        if (selectedUser && Object.keys(selectedUser).length > 0) {
            const acccodeList = [];
            const emailParts = selectedUser.email.split("@");

            selectedUser.listacct.filter(item => item.accountNumber != "INIT00000001").forEach((item) =>
                acccodeList.push(item.accountCode));
            setEditFlow(true);
            // console.log("init group", selectedUser.userGroup)
            setInitValues({
                //edit user
                firstName: selectedUser.firstname,
                lastName: selectedUser.lastName,
                userName: selectedUser.username,
                // email: selectedUser.email,
                email: emailParts[0],
                emailDomain: "@"+emailParts[1],
                country: selectedUser.country,
                phoneNumber: selectedUser.phoneNumber,
                orgCode: organizations.orgcode,
                userRolesDTOS: [],
                resourceGroupDTOS: selectedUser.userGroup,
                acccodes: acccodeList,
                isEditFlow: true
            });

        } 
        else {
            //add user
            if (isEditFlow){
                setEditFlow(false);
                setInitValues({
                    ...initValues,
                    firstName: '',
                    lastName: '',
                    userName: '',
                    password: '',
                    email: '',
                    // emailDomain: '@cloudnovatech.com',
                    country: '',
                    phoneNumber: '',
                    countryCode: '',
                    orgCode: organizations.orgcode,
                    acccodes: [],
                    userRolesDTOS: Array.isArray(selectedUser.userRolesDTOS)
                        ? selectedUser.userRolesDTOS
                        : selectedUser.userRolesDTOS
                        ? [selectedUser.userRolesDTOS]
                        : [],
                    resourceGroupDTOS: []
                })            
            } 
        }

    }, [selectedUser])

    //For displaying error message of user creation
    useEffect(() => {
        if (addNewUserError || addNewUserMessage || editUserError || editUserMessage) {
            setTimeout(() => {
                console.log("About to call clearAllUserListMessages");
                dispatch(clearAllUserListMessages());

          }, 10000); // Hide all messages after 10 seconds
        }
    }, [addNewUserMessage, addNewUserError, editUserMessage, editUserError]);

    useEffect(() => {
        if (addNewUserMessage || editUserMessage) {
            if (showAdduser){
                setShowAddUsers(false);
            }             
        }
    }, [setShowAddUsers, addNewUserMessage, editUserMessage]);

    const onSubmitHandler = async (values, { setSubmitting }) => {
        try {
          setSubmitting(true);
    
          // Modify values as needed (e.g., concatenate email and emailDomain)
          const userRequest = { ...values };
          userRequest.email = userRequest.email + userRequest.emailDomain;
          delete userRequest.emailDomain;
    
          const encryptedPassword = await EncryptPassword(userRequest.password);
          userRequest.password = encryptedPassword;
    
          // Modify phoneNumber and countryCode synchronously
          userRequest.countryCode = ExtractCountryCallingCode(userRequest.phoneNumber);
          userRequest.phoneNumber = ExtractPhoneNumber(userRequest.phoneNumber);

          userRequest.userRolesDTOS = Array.isArray(values.userRolesDTOS)
            ? values.userRolesDTOS
            : [values.userRolesDTOS];
    
          console.log("Modified userRequest:", userRequest);
    
          // Handle form submission (e.g., API call) with modified userRequest
          handleOnSubmit(userRequest);
    
        } catch (error) {
          console.error("Error during form submission:", error);
          // Optionally handle error (e.g., show error message to the user)
        } finally {
          setSubmitting(false);
        }
      };

    return (
        <>
            <Modal isOpen={showAdduser} className="col-sm-8" size="lg">
            <ModalHeader toggle={() => setShowAddUsers(false)} style={{ backgroundColor: '#E3712C' }}>
                <div className="title">{isEditFlow ? 'Edit User' : 'Add New User'}</div>
            </ModalHeader>
            <Formik initialValues={initValues}
                validate={(values) => {
                    const errors = {};
                    ['firstName',
                        'lastName',
                        'userName',
                        'email',
                        'country',
                        'phoneNumber'].forEach((item) => {
                            if (!values[item]) {
                                errors[item] = 'Required'
                            }
                        })
                    if(!isEditFlow){
                        if (!values.password) {
                            errors.password = "Password is required.";
                          } else if (!passwordRegex.test(values.password)) {
                            errors.password =
                              "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."; 
                        }
                    }
                    
                    if (values.acccodes && values.acccodes.length === 0) {
                        if (!(values.resourceGroupDTOS && values.resourceGroupDTOS.length > 0)) {
                            errors.acccodes = 'Select either Resource Group or Account'
                        }
                    }
                    if (values.resourceGroupDTOS && values.resourceGroupDTOS.length === 0) {
                        if (!(values.acccodes && values.acccodes.length > 0)) {
                            errors.resourceGroupDTOS = 'Select either Resource Group or Account'
                        }
                    }
                    if (!isEditFlow) {
                        if (values.userRolesDTOS && values.userRolesDTOS.length === 0) {
                            if (!(values.userRolesDTOS && values.userRolesDTOS.length > 0)) {
                                errors.userRolesDTOS = 'Select a Role'
                            }
                        }
                    }
                    if (values.phoneNumber) {
                        const phoneNumber = values.phoneNumber;
                        // console.log('--== _addNewUser :::> ', phoneNumber);
                        if (!isPossiblePhoneNumber(phoneNumber)) {
                            errors.phoneNumber = 'Please enter a valid phone number'
                        }
                    }

                    // console.log('--== _addNewUser errors', errors);

                    // const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    const regex = /^[^\s@]+$/;
                    if (values.email && !regex.test(String(values.email).toLowerCase())) {
                        errors.email = 'Please enter a valid email id'
                    } else if (!isEditFlow) {
                        if(validateEmailExistence(values)){
                            errors.email = validateEmailExistence(values);
                        }                        
                    }
                    console.log(" errors: ", errors);
                    return errors;
                }}
                onSubmit={onSubmitHandler}
                enableReinitialize
            >
                {({ values, errors, isValid, isSubmitting, touched, setFieldValue, handleSubmit, handleChange, handleBlur, setFieldTouched }) => {

                    const handleMultiSelectChange = (event) => {

                        // console.log("event is1212", event)
                        setFieldTouched("resourceGroupDTOS", true, false)
                        var gprs = []
                        if (event != null) {
                            if (isEditFlow) {
                                event.map((grp) => {

                                    gprs.push({ resourcename: grp.value })

                                })
                            }
                            else {
                                event.map((grp) => {

                                    gprs.push({ resourcename: grp.value })

                                })
                            }
                        }
                        setFieldValue("resourceGroupDTOS", gprs);
                        // console.log("resourcegrps69", values.resourceGroupDTOS)
                    }

                    const handlePhoneChange = (value) => {
                        setFieldValue("phoneNumber", value);
                    }
                
                    const selectedCount = values.acccodes.length;
                    const availableCount = accounts.length - selectedCount;
                    const selectedResourceGroups = values.resourceGroupDTOS.map((rg) => rg.resourcename);

                    // Filter the resource groups based on the selectedResourceGroups
                    const filteredAccounts = organizations.resourceGroups
                      .filter((orgGrp) => selectedResourceGroups.includes(orgGrp.resourcename))
                      .flatMap((accGrp) => accGrp.acccodes);
                     values.acccodes = filteredAccounts.map((acc) => acc.accountCode);

                    return (
                        <>
                            <ModalBody>
                                <div className="row">
                                    <div className="col-sm-12 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="firstNameField">First Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstNameField"
                                                name="firstName"
                                                aria-describedby="First Name"
                                                placeholder="First Name"
                                                value={values.firstName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required />
                                            {errors.firstName && touched.firstName && (
                                                <div className="text-danger">{errors.firstName}</div>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="lastNameField">Last Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastNameField"
                                                name="lastName"
                                                aria-describedby="Last Name"
                                                placeholder="Last Name"
                                                value={values.lastName}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                required />
                                            {errors.lastName && touched.lastName && (
                                                <div className="text-danger">{errors.lastName}</div>
                                            )}
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
                                                <div className="form-group">
                                                    <label htmlFor="emailField">Email</label>
                                                    <Row>
                                                        <Col lg="6" md="6" sm="6" style={{paddingRight: "0px"}}>
                                                            <input
                                                                type="email"
                                                                className="form-control"
                                                                id="emailField"
                                                                name="email"
                                                                aria-describedby="Email"
                                                                placeholder="Email"
                                                                value={values.email}
                                                                onChange={(e) => {
                                                                    // console.log("### emailDomain : ", values);
                                                                    setFieldValue("email", e.target.value);
                                                                    setFieldValue("userName", e.target.value+values.emailDomain);
                                                                }}
                                                                onBlur={handleBlur}
                                                                disabled={isEditFlow}
                                                                required
                                                                style={{borderTopRightRadius: "0px", borderBottomRightRadius: "0px"}} 
                                                            />
                                                        </Col>
                                                        <Col lg="6" md="6" sm="6" style={{paddingLeft: "0px"}}>
                                                            <input 
                                                                required 
                                                                className="form-control" 
                                                                id="emailDomainField" 
                                                                name="emailDomain" 
                                                                value={values.emailDomain}
                                                                onChange={handleChange}
                                                                // onBlur={handleBlur}
                                                                style={{
                                                                    borderTopLeftRadius: "0px", 
                                                                    borderBottomLeftRadius: "0px", 
                                                                    borderLeft: "0px", 
                                                                    backgroundColor: "lightgray"
                                                                }}
                                                                disabled
                                                            />
                                                        </Col>
                                                    </Row>                                         
                                                    {errors.email && touched.email ? (
                                                        <div className="text-danger">{errors.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !isEditFlow && (
                                                <div className="form-group">
                                                    <label htmlFor="passwordField">Password</label>
                                                    <div style={{position: "relative"}}>
                                                        <input
                                                            type={showPassword ? "text" : "password"}
                                                            className="form-control"
                                                            id="passwordField"
                                                            name="password"
                                                            aria-describedby="Password"
                                                            placeholder="Password"
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            required />
                                                        <IconButton
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                            style={{
                                                                position: "absolute",
                                                                top: "50%",
                                                                right: "5px",
                                                                transform: "translateY(-50%)",
                                                                cursor: "pointer",
                                                                fontSize: "20px"
                                                            }}
                                                        >
                                                            {showPassword ? (
                                                            <Visibility />
                                                            ) : (
                                                            <VisibilityOff />
                                                            )}
                                                        </IconButton>
                                                    </div>
                                                    {errors.password && touched.password && (
                                                        <div className="text-danger">{errors.password}</div>
                                                    )}
                                                </div>
                                            )
                                        }
                                        <div className="form-group">
                                            <label htmlFor="phoneNumberField">Phone Number</label>
                                            <PhoneInput
                                                type="text"
                                                // className="form-control"
                                                id="phoneNumberField"
                                                name="phoneNumber"
                                                aria-describedby="Phone Number"
                                                placeholder="Phone Number"
                                                value={values.phoneNumber}
                                                maxLength={16}
                                                // onChange={(e) => handlePhoneNumberChange(e)}
                                                onChange={handlePhoneChange}
                                                onBlur={handleBlur}
                                                required />
                                            {errors.phoneNumber && touched.phoneNumber ? (
                                                <div className="text-danger">{errors.phoneNumber}</div>
                                            ) : null}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="userNameField">User Name</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="userNameField"
                                                name="userName"
                                                aria-describedby="User Name"
                                                placeholder="User Name"
                                                value={values.userName}
                                                onBlur={handleBlur}
                                                disabled={true}
                                                required />
                                            {errors.userName 
                                            && touched.userName 
                                            && (
                                                <div className="text-danger">{errors.userName}</div>
                                            )}

                                        </div>
                                    </div>
                                    <div className="col-sm-12 col-md-6">

                                        <div className="form-group">
                                            <label htmlFor="countryField">Country</label>
                                            <select required className="custom-select" id="countryField" name="country" value={values.country}
                                                onChange={handleChange}
                                                onBlur={handleBlur}>
                                                <option selected>Choose...</option>
                                                {
                                                    countryList.map((item, index) => <option value={item} key={`country_${item}_${index}`}>{item}</option>)
                                                }
                                            </select>
                                            {errors.country && touched.country ? (
                                                <div className="text-danger">{errors.country}</div>
                                            ) : null}
                                        </div>
                                        {
                                            !isEditFlow && (
                                                <div className="form-group">
                                                <label htmlFor="roleField">Role</label>
                                                <select
                                                    required
                                                    className="custom-select"
                                                    id="roleField"
                                                    name="userRolesDTOS"
                                                    value={values.userRolesDTOS[0] || ''}
                                                    onChange={(e) => setFieldValue('userRolesDTOS', [e.target.value])} 
                                                    onBlur={handleBlur}
                                                >
                                                    <option value="">Select a Role</option> 
                                                    {userRole === "Administrator" && (
                                                    <option value="Administrator">Administrator</option>
                                                    )}
                                                    <option value="PowerUser">PowerUser</option>
                                                    <option value="StandardUser">StandardUser</option>
                                                </select>
                                                {errors.userRolesDTOS && touched.userRolesDTOS ? (
                                                    <div className="text-danger">{errors.userRolesDTOS}</div>
                                                ) : null}
                                                </div>
                                            )
                                        }
                                        <Select
                                            name="rgs"
                                            closeMenuOnSelect={false}
                                            onChange={handleMultiSelectChange}
                                            isMulti
                                            isClearable
                                            default
                                            placeholder={"Resource Groups ..."}
                                            value={
                                                values.resourceGroupDTOS.map((rg) => {

                                                    return { value: rg.resourcename, label: rg.resourcename }
                                                })}
                                            options={resourceGroups.map((rg) => {
                                                return { value: rg.resourcename, label: rg.resourcename }
                                            })}
                                        />

                                        <div className="form-group" style={{height: "200px", overflowY: "auto"}}>
                                            <label htmlFor="accountField">Cloud Accounts</label>
                                            <Table>
                                                <TableHead >
                                                    <TableRow style={{padding: "4px", fontStyle: "italic", background: "orange", position: "sticky", top: "0px"}}>
                                                        <TableCell style={{padding: "4px"}}>Account Name</TableCell>
                                                        <TableCell style={{padding: "4px"}}>Account Code</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                {filteredAccounts.map((acc) => {
                                                    return (
                                                            <TableRow key={acc.accountCode} style={{padding: "4px"}}>
                                                                <TableCell style={{padding: "4px"}}>{acc.accountName}</TableCell>
                                                                <TableCell style={{padding: "4px"}}>{acc.accountCode}</TableCell>
                                                            </TableRow>
                                                            )
                                                    })
                                                }
                                                </TableBody>
                                            </Table>
                                            {errors.acccodes && touched.acccodes ? (
                                                <div className="text-danger">{errors.acccodes}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <div className="d-flex" style={{alignItems: "center"}}>
                                    <button type="button"
                                        className="btn btn-primary m-2"
                                        disabled={Object.keys(touched).length === 0 || !isValid || addNewUserLoading || editUserLoading}
                                        onClick={handleSubmit}>Submit</button>
                                </div>
                            </ModalFooter>

                        </>
                    )
                }}
            </Formik>
            </Modal>
            <Snackbar
                open={addNewUserError!==null}
                autoHideDuration={5000}
            >
                <Alert severity="error">{addNewUserError}</Alert>
            </Snackbar>
            <Snackbar
                open={addNewUserMessage!==null}
                autoHideDuration={5000}
            >
                <Alert severity="success">{addNewUserMessage}</Alert>
            </Snackbar>
            <Snackbar
                open={editUserError!==null}
                autoHideDuration={5000}
            >
                <Alert severity="error">{editUserError}</Alert>
            </Snackbar>
            <Snackbar
                open={editUserMessage!==null}
                autoHideDuration={5000}
            >
                <Alert severity="success">{editUserMessage}</Alert>
            </Snackbar>
        </>        
    )

}

export default AddNewUser;