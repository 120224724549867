import { Tab, Tabs } from '@mui/material'
import React from 'react'

const SectionTabs = ({sections, selectedTab, handleTabChange}) => {
  return (
    <Tabs value={selectedTab} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
        {sections.map((tab) => (
          <Tab label={tab} value={tab} key={tab}  sx={{fontSize: "0.75rem"}}/>
        ))}
    </Tabs>
  )
}

export default SectionTabs