import { combineReducers } from "redux";

// Front
import layout from "./layout/reducer";
import orgList from "./orgList/reducer";
import acctList from "./acctList/reducer";
import userList from "./userList/reducer";
import licensing from "./licensing/reducer";
import notify from "./notify/reducer";
import ruleList from "./rulesList/reducer";
// Authentication Module
import login from "./auth/login/reducer";
import account from "./auth/register/reducer";
import forgetPassword from "./auth/forgetPwd/reducer";
import orgAccount from "./auth/orgAcct/reducer";
import profile from "./auth/profile/reducer";
import awsAcc from "./awsAcc/reducer";
import tableauList from "./tableau/reducer";
import header from "./header/reducer";
//AWS, License Information
import pricingInfo from "./pricingInfo/reducer";

//AWS, Metric Information
import metricInfo from "./metric/reducer";
import resourceGroup from "./resourceGroup/reducer";

import ruleRemediation from "./ruleRemediation/reducer";

// IaaC
import iaac from "./iaac/reducer";
import iaacSubscription from "./iaacSubscription/reducer";
import iaacLicense from "./iaacLicense/reducer";

// Release notes
import releaseNotes from "./releaseNotes/reducer";

// Support
import support from "./support/reducer";

// AWS Marketplace
import awsMarketplace from "./awsMarketplace/awsMarketplaceSlice";

// Feature Configuration
import featureConfiguration from "./featureConfiguration/featureConfigurationSlice";

// Account Configuration
import accountConfiguration from "./accountConfiguration/accountConfigurationSlice";

// scanService Reducer
import scanService from "./scanService/scanServiceSlice";

// Kibana Dashboard Reducer
import kibanaDashboards from "./kibanaDashboards/kibanaDashboardsSlice";

// Billing Slice Reducer
import billing from "./billing/billingSlice";

// Tag Allocation
import tags from "./tagAllocation/tagAllocationSlice";

// Org Scope
import orgScope from "./auth/getOrgScope/orgScopeSlice";

// WAFR Questionnaire
import wafrQuestionnaire from "./wafrQuestionnaire/wafrQuestionnaireSlice";

// CIS WAFR
import cisWafr from "./cisWafr/cisWafrSlice";

import { RESET_STORE } from "./actionTypes";



const rootReducer = combineReducers({
  // public
  layout,
  orgList,
  acctList,
  userList,
  licensing,
  notify,
  ruleList,
  awsAcc,
  tableauList,

  // Authentication
  login,
  forgetPassword,
  account,
  orgAccount,
  profile,
  header,
  //AWS, License Information
  pricingInfo,
  //AWS, Metric Information
  metricInfo,
  resourceGroup,
  ruleRemediation, // Rule Remediation

  // IaaC
  iaac,
  iaacSubscription,
  iaacLicense,

  // Release Notes
  releaseNotes,

  // Support
  support,
  // AWS Marketplace
  awsMarketplace,
  // Feature Configuration
  featureConfiguration,
  // Account Configuration
  accountConfiguration,
  // scanService
  scanService,
  // kibana dashboards
  kibanaDashboards,
  // billing
  billing,
  // Tag Allocation
  tags,
  // Org Scope
  orgScope,
  // WAFR Questionnaire
  wafrQuestionnaire,
  // CIS Wafr
  cisWafr,
});

const resettableReducer = (state, action) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return rootReducer(state, action);
};

export default resettableReducer;
