import React, { useState } from "react";
import { Box, Typography, Paper, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SectionTabs from "./SectionTabs";
import sectionDescriptions from "../sectionDescriptions.json";
import SectionDetails from "./SectionDetails";

const WorkloadAssessment = ({
  wlaData,
  orgCode,
  accCode,
  accNumber,
  assessmentName,
  frameworkName,
  ownerName,
  approverName,
}) => {
  const [selectedTab, setSelectedTab] = useState("Operational Excellence");
  const [selectedQuestion, setSelectedQuestion] = useState(0);

  if (!wlaData) {
    return null;
  }

  const tabSections = Object.keys(wlaData);

  const sectionInfo = sectionDescriptions[selectedTab];
  const sectionQuestionDetailList = wlaData[selectedTab];
  const sectionQuestionList = sectionQuestionDetailList?.map((que) => ({
    questionTitle: que.questionTitle,
    risk: que.risk,
    reason: que.reason,
  }));
  const sectionQuestionLabel = sectionInfo?.label;

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedQuestion(0); // Reset to the first question in the new tab
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        overflow: "hidden",
        width: "100%",
      }}
    >
      {/* Updated Header Section */}
      <Paper
        elevation={2}
        sx={{
          padding: "0.8rem 1.2rem",
          borderRadius: "8px",
          marginBottom: "1rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "#f9fafe",
        }}
      >
        {/* Left Section: All Details in One Line */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "1.5rem",
            fontSize: "0.875rem", // Small, professional text
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "600",
              color: "#2a3c50",
              whiteSpace: "nowrap",
            }}
          >
            {assessmentName || "Workload Name N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Framework Name: {frameworkName || "N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Assessment Owner: {ownerName || "N/A"}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              fontWeight: "400",
              color: "#6b7280",
            }}
          >
            | Approver Name: {approverName || "N/A"}
          </Typography>
        </Box>

        {/* Right Section: Back Button/Icon */}
        <Button
          href="/workload-assessment"
          variant="text"
          startIcon={<ArrowBackIcon />}
          sx={{
            textTransform: "none",
            fontWeight: "500",
            color: "#1976d2",
            fontSize: "0.875rem",
            "&:hover": {
              backgroundColor: "#e3f2fd",
            },
          }}
        >
          Back
        </Button>
      </Paper>

      {/* Main Content */}
      <Paper
        sx={{
          backgroundColor: "white",
          borderRadius: "0.5rem",
          padding: "0.5rem",
        }}
      >
        <SectionTabs
          sections={tabSections}
          selectedTab={selectedTab}
          handleTabChange={handleTabChange}
        />
      </Paper>
      <Box
        sx={{
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        <SectionDetails
          sectionQuestionLabel={sectionQuestionLabel}
          sectionQuestionList={sectionQuestionList}
          sectionQuestionDetailList={sectionQuestionDetailList}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          orgCode={orgCode}
          accCode={accCode}
          accNumber={accNumber}
        />
      </Box>
    </Box>
  );
};

export default WorkloadAssessment;
