import { fork, put, all, call, takeLatest } from "redux-saga/effects";

import {
  getWafrQuestionnaireSuccessful,
  getWafrQuestionnaireFailure,
  clearAllWafrQuestionnaireMessagesSuccessful,
  getAssessmentListSuccessful,
  getAssessmentListFailure,
  updateWafrAnswerSuccessful,
  updateWafrAnswerFailure,
  postWafrAssessmentSuccessful,
  postWafrAssessmentFailure,
} from "./wafrQuestionnaireSlice";

import {
  getWafrQuestionnaire as getWafrQuestionnaireApi,
  getWafrAssessmentList as getWafrAssessmentListApi,
  updateWafrAnswer as updateWafrAnswerApi,
  postWafrAssessment as postWafrAssessmentApi,
} from "../../helpers/auth_aws_helper";

function* getWafrQuestionnaireSaga({ payload: getWafrQuestionnairePayload }) {
  try {
    const response = yield call(
      getWafrQuestionnaireApi,
      getWafrQuestionnairePayload
    );
    console.log("*getWafrQuestionnaireApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(getWafrQuestionnaireSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching questionnaire.";
      yield put(getWafrQuestionnaireFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getWafrQuestionnaireFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(
        getWafrQuestionnaireFailure({ message: "No response received" })
      );
    } else {
      yield put(getWafrQuestionnaireFailure({ message: error?.message }));
    }
  }
}

// Saga for fetching Assessment List
function* getAssessmentListSaga() {
  try {
    const response = yield call(getWafrAssessmentListApi);
    console.log("*getWafrAssessmentListApi() response: ", response);

    // Log structure of response for debugging
    console.log("Response status: ", response.status);
    console.log("Response data: ", response.data);

    if (response && response.status === 200) {
      // Extract and transform the response data
      const rawData = response.data;
      const transformedData = Object.keys(rawData)
        .filter((key) => key !== "status") // Exclude the "status" key
        .map((key) => ({
          id: key, // Use the numeric key as the ID
          ...rawData[key],
        }));
      console.log(
        "🚀 ~ function*getAssessmentListSaga ~ transformedData:",
        transformedData
      );
      // Dispatch the successful action with the transformed data
      yield put(getAssessmentListSuccessful(transformedData));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while fetching assessment list.";
      yield put(getAssessmentListFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        getAssessmentListFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(getAssessmentListFailure({ message: "No response received" }));
    } else {
      yield put(getAssessmentListFailure({ message: error?.message }));
    }
  }
}

function* updateWafrAnswerSaga({ payload: updateWafrAnswerPayload }) {
  try {
    const response = yield call(updateWafrAnswerApi, updateWafrAnswerPayload);
    console.log("*updateWafrAnswerApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      yield put(updateWafrAnswerSuccessful(response));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while updating answer.";
      yield put(updateWafrAnswerFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        updateWafrAnswerFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(updateWafrAnswerFailure({ message: "No response received" }));
    } else {
      yield put(updateWafrAnswerFailure({ message: error?.message }));
    }
  }
}

function* postWafrAssessmentSaga({ payload: postWafrAssessmentPayload }) {
  try {
    const response = yield call(
      postWafrAssessmentApi,
      postWafrAssessmentPayload
    );
    console.log("*postWafrAssessmentApi() response: ", response);

    if (response && response.status === 200) {
      delete response.status;
      console.log("**postWafrAssessmentApi() response: ", response);
      yield put(postWafrAssessmentSuccessful(response.data));
    } else {
      const message =
        response?.data?.message ||
        response?.data ||
        "Unexpected error occurred while creating assessment.";
      yield put(postWafrAssessmentFailure({ message }));
    }
  } catch (error) {
    if (error.response) {
      yield put(
        postWafrAssessmentFailure({
          message: `Error Code: ${error.response?.status} , Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(postWafrAssessmentFailure({ message: "No response received" }));
    } else {
      yield put(postWafrAssessmentFailure({ message: error?.message }));
    }
  }
}

function* clearAllWafrQuestionnaireMessagesSaga() {
  yield put(clearAllWafrQuestionnaireMessagesSuccessful());
}

function* watchWafrQuestionnaire() {
  yield takeLatest(
    "wafrQuestionnaire/getWafrQuestionnaire",
    getWafrQuestionnaireSaga
  );
  yield takeLatest(
    "wafrQuestionnaire/clearAllWafrQuestionnaireMessages",
    clearAllWafrQuestionnaireMessagesSaga
  );
}

function* watchWafrAnswer() {
  yield takeLatest("wafrQuestionnaire/updateWafrAnswer", updateWafrAnswerSaga);
}

function* watchWafrAssessment() {
  yield takeLatest(
    "wafrQuestionnaire/postWafrAssessment",
    postWafrAssessmentSaga
  );
}

function* clearAllAssessmentMessagesSaga() {
  yield put({ type: "wafrQuestionnaire/clearAllAssessmentMessages" });
}

function* watchAssessmentList() {
  yield takeLatest(
    "wafrQuestionnaire/getAssessmentList",
    getAssessmentListSaga
  );
  yield takeLatest(
    "wafrQuestionnaire/clearAllAssessmentMessages",
    clearAllAssessmentMessagesSaga
  );
}

function* watchWafrQuestionnaireSaga() {
  yield all([
    fork(watchWafrQuestionnaire),
    fork(watchAssessmentList),
    fork(watchWafrAnswer),
    fork(watchWafrAssessment),
  ]);
}

export default watchWafrQuestionnaireSaga;
