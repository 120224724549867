import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Modal,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import awsRegions from "aws-regions";
import { useDispatch, useSelector } from "react-redux";
import { postWafrAssessment } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

const CreateAssessmentModal = ({ open, onClose, accounts, users, orgCode }) => {
  const dispatch = useDispatch();
  const { isPostingWafrAssessment } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const [assessmentName, setAssessmentName] = useState("");
  const [assessmentDescription, setAssessmentDescription] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [assessmentAwsRegions, setAssessmentAwsRegions] = useState([]);
  const [assessmentApprover, setAssessmentApprover] = useState("");
  const [assessmentOwner, setAssessmentOwner] = useState("");

  // console.log("users : ", users);

  const handleCreateAssessment = () => {
    const createAssessmentPayload = {
      orgCode: orgCode,
      accountCode: accounts?.find(
        (account) => account.accountNumber === accountNumber
      )?.accountCode,
      accountNumber: accountNumber,
      workloadName: assessmentName,
      description: assessmentDescription,
      regions: [...assessmentAwsRegions],
      assessmentApprover: assessmentApprover,
      assessmentOwner: assessmentOwner,
    };
    console.log("createAssessmentPayload = ", createAssessmentPayload);
    dispatch(postWafrAssessment(createAssessmentPayload));
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="create-assessment-modal"
      aria-describedby="modal-for-creating-assessment"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 500,
          bgcolor: "background.paper",
          borderRadius: "10px",
          boxShadow: 24,
          p: 0,
        }}
      >
        {/* Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#F5F5F5",
            padding: "16px 24px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottom: "1px solid #E0E0E0",
          }}
        >
          <Typography variant="h6" fontWeight="bold">
            Create Workload
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider />

        {/* Form */}
        <Box
          component="form"
          sx={{
            padding: "24px",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <TextField
            label="Assessment Name"
            placeholder="Enter a name"
            required
            value={assessmentName}
            onChange={(e) => setAssessmentName(e.target.value)}
          />

          <TextField
            label="Assessment Description"
            placeholder="Enter a description"
            multiline
            minRows={3}
            value={assessmentDescription}
            onChange={(e) => setAssessmentDescription(e.target.value)}
          />

          <FormControl fullWidth>
            <InputLabel id="account-number-label-id">Account Number</InputLabel>
            <Select
              labelId="account-number-label-id"
              label="Account Number"
              value={accountNumber}
              onChange={(e) => setAccountNumber(e.target.value)}
            >
              {accounts?.map((account) => (
                <MenuItem
                  key={account?.accountNumber}
                  value={account?.accountNumber}
                >
                  {account?.accountNumber} - {account?.accountName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>AWS Region</InputLabel>
            <Select
              multiple
              value={assessmentAwsRegions}
              onChange={(e) => setAssessmentAwsRegions(e.target.value)}
              label="AWS Region"
              renderValue={(selected) => selected.join(", ")}
            >
              <MenuItem value="">
                <em>Select AWS Region</em>
              </MenuItem>
              {awsRegions.list().map((region) => (
                <MenuItem key={region.code} value={region.code}>
                  <Checkbox
                    checked={assessmentAwsRegions.includes(region.code)}
                  />
                  {region.name}
                </MenuItem>
              ))}
            </Select>
            {/* <FormHelperText>{errors.selectedRegion}</FormHelperText> */}
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="assessment-approver-label-id">
              Assessment Approver
            </InputLabel>
            <Select
              labelId="assessment-approver-label-id"
              label="Assessment Approver"
              value={assessmentApprover}
              onChange={(e) => setAssessmentApprover(e.target.value)}
            >
              {users?.map((user) => (
                <MenuItem key={user?.username} value={user?.username}>
                  {user?.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="assessment-owner-label-id">
              Assessment Owner
            </InputLabel>
            <Select
              labelId="assessment-owner-label-id"
              label="Assessment Owner"
              value={assessmentOwner}
              onChange={(e) => setAssessmentOwner(e.target.value)}
            >
              {users?.map((user) => (
                <MenuItem key={user?.username} value={user?.username}>
                  {user?.username}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Action Buttons */}
          <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={isPostingWafrAssessment}
              onClick={handleCreateAssessment}
            >
              {!isPostingWafrAssessment ? "Save" : "Saving..."}
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateAssessmentModal;
