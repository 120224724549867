import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import GetKibanaDashboard from "../../components/Common/HelperComponents/KibanaDashboardComponents/GetKibanaDashboard";
import { useParams } from "react-router-dom/cjs/react-router-dom";

const CISWAFDashboard = () => {
  const [activeTab, setActiveTab] = useState("waf");
  const { accountNumber } = useParams();
  console.log("🚀 ~ CISWAFDashboard ~ accountNumber:", accountNumber)

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "waf":
        return <GetKibanaDashboard dashboardKey={"cis_waf"} params={accountNumber} />;
      default:
        return null;
    }
  };

  return (
    <div style={{ marginTop: "5rem" }}>
      <Tabs value={activeTab} onChange={handleTabChange}>
        <Tab label="CIS" value="waf" />
      </Tabs>
      {renderTabContent()}
    </div>
  );
};

export default CISWAFDashboard;
