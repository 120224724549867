import React from "react";
import { Box, Typography, TextField } from "@mui/material";

const NotesBox = ({ notes, setNotes, noRows = 2 }) => {
  const handleNotesChange = (event) => {
    const updatedNotes = event.target.value;
    setNotes(updatedNotes);
  };

  return (
    <Box
      sx={{
        padding: "0.5rem",
        borderRadius: "0.25rem",
        border: "1px solid #ccc",
        backgroundColor: "#F9F9F9",
        marginBottom: "0.5rem",
        marginRight: "0.5rem",
      }}
    >
      <Typography
        sx={{
          fontSize: "0.8rem",
          fontWeight: "bold",
          marginBottom: "0.25rem",
        }}
      >
        Notes - Optional
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={noRows}
        placeholder="Write your notes here..."
        value={notes}
        onChange={handleNotesChange}
        variant="outlined"
        size="small"
        InputProps={{
          sx: { fontSize: "0.8rem" },
        }}
        InputLabelProps={{
          sx: { fontSize: "0.8rem" },
        }}
        sx={{
          backgroundColor: "#FFFFFF",
        }}
      />
    </Box>
  );
};

export default NotesBox;
