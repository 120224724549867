import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  LinearProgress,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import {
  clearAllWafrQuestionnaireMessages,
  getAssessmentList,
} from "../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import CreateAssessmentModal from "./components/CreateAssessmentModal";
import {
  useAccounts,
  useOrganization,
  useUsers,
} from "../../components/Common/HelperComponents/GetDataFromStore";
import { enqueueSnackbar } from "notistack";

const WorkloadListPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const accounts = useAccounts();
  const organization = useOrganization();

  console.log("~~~organization : ", organization);

  // Selectors to access data from the Redux store
  const { assessmentList, isGettingAssessmentList, getAssessmentListError } =
    useSelector((state) => state.wafrQuestionnaire);
  const { postWafrAssessmentMessage, postWafrAssessmentError } = useSelector(
    (state) => state.wafrQuestionnaire
  );

  const { usersLoading, users } = useUsers();

  // Fetch the assessment list when the component mounts
  useEffect(() => {
    dispatch(getAssessmentList());
  }, [dispatch]);

  useEffect(() => {
    if (postWafrAssessmentMessage) {
      // Show success notification
      enqueueSnackbar(postWafrAssessmentMessage, { variant: "success" });

      // Close the modal
      setIsModalOpen(false);

      // Refresh the DataGrid by re-dispatching getAssessmentList
      dispatch(getAssessmentList());
      console.log("postWafrAssessmentMessage = ", postWafrAssessmentMessage);
    }
    if (postWafrAssessmentError) {
      enqueueSnackbar(postWafrAssessmentError, { variant: "error" });
      console.log("postWafrAssessmentError = ", postWafrAssessmentError);
    }
    if (postWafrAssessmentMessage || postWafrAssessmentError) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [postWafrAssessmentMessage, postWafrAssessmentError, dispatch]);

  // Define DataGrid Columns
  const columns = [
    {
      field: "Assessment Name",
      headerName: "Assessment Name",
      width: 300,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Account Number",
      headerName: "Account Number",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Progress percentage",
      headerName: "Progress",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          width="100%"
        >
          <LinearProgress
            variant="determinate"
            value={params.value}
            sx={{
              width: "80%",
              height: 8,
              borderRadius: 4,
              backgroundColor: "#e0e0e0",
              "& .MuiLinearProgress-bar": {
                backgroundColor: "#1976d2",
              },
            }}
          />
          <Typography
            variant="caption"
            sx={{ ml: 1, fontWeight: "500", color: "#555" }}
          >
            {params.value}%
          </Typography>
        </Box>
      ),
    },
    {
      field: "Assessment Approver",
      headerName: "Assessment Approver",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Assessment Owner",
      headerName: "Assessment Owner",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "OrgCode",
      headerName: "Org Code",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Framework(s)",
      headerName: "Framework(s)",
      width: 250,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      valueGetter: (params) =>
        new Date(params.row.createdDate).toLocaleDateString(),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedDate",
      headerName: "Last Modified Date",
      width: 200,
      valueGetter: (params) =>
        new Date(params.row.lastModifiedDate).toLocaleDateString(),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastModifiedBy",
      headerName: "Last Modified By",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 150,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Button
          variant="outlined"
          size="small"
          onClick={() =>
            history.push(`/workload-assessment/${params.row["Workload ID"]}`, {
              orgCode: params.row["OrgCode"],
              accCode: params.row["Account Code"],
              accNumber: params.row["Account Number"],
              assessmentName: params.row["Assessment Name"],
              frameworkName: params.row["Framework(s)"],
              ownerName: params.row["Assessment Owner"],
              approverName: params.row["Assessment Approver"],
            })
          }
          sx={{
            textTransform: "none",
            fontWeight: 500,
            borderRadius: "20px",
            padding: "2px 12px",
            borderColor: "#1976d2",
            color: "#1976d2",
            "&:hover": {
              backgroundColor: "#E3F2FD",
              borderColor: "#1565c0",
            },
          }}
        >
          View
        </Button>
      ),
    },
  ];

  // Custom Toolbar with Integrated Search
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          justifyContent: "space-between",
          padding: "0.5rem 1rem",
        }}
      >
        <Box display="flex" alignItems="center">
          <GridToolbarQuickFilter
            quickFilterParser={(searchInput) =>
              searchInput.split(",").map((value) => value.trim())
            }
            placeholder="Search Assessments"
            sx={{
              "& input": {
                borderRadius: "8px",
                backgroundColor: "#F1F1F1",
                padding: "8px 12px",
              },
            }}
            debounceMs={300}
          />
        </Box>
        <GridToolbarColumnsButton />
      </GridToolbarContainer>
    );
  }

  return (
    <Box
      className="page-content"
      sx={{
        padding: "1.5rem 2rem",
        mt: "5rem",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" fontWeight="bold" color="#333">
          Workload Assessments
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => setIsModalOpen(true)}
          sx={{
            textTransform: "none",
            fontWeight: "bold",
            backgroundColor: "#1976d2",
            padding: "6px 16px",
            borderRadius: "20px",
            boxShadow: "none",
            "&:hover": {
              backgroundColor: "#1565c0",
              boxShadow: "none",
            },
          }}
          disabled={usersLoading}
        >
          Create Assessment
        </Button>
      </Box>

      {/* DataGrid */}
      {isGettingAssessmentList ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : getAssessmentListError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            backgroundColor: "#fef8f8",
            border: "1px solid #f44336",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#f44336"
            sx={{ mb: 1 }}
          >
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            {getAssessmentListError}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={() => dispatch(getAssessmentList())}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Retry
          </Button>
        </Box>
      ) : (
        <DataGrid
          rows={
            assessmentList?.map((row, index) => ({ id: index, ...row })) || []
          }
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          autoHeight
          components={{ Toolbar: CustomToolbar }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                "Account Number": true,
                "Assessment Name": true,
                "Progress percentage": true,
                "Assessment Approver": true,
                "Assessment Owner": true,
                OrgCode: false,
                "Framework(s)": false,
                lastModifiedDate: false,
                lastModifiedBy: false,
                createdDate: false,
                createdBy: false,
              },
            },
          }}
          loading={isGettingAssessmentList}
          rowHeight={40}
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            maxHeight: "calc(100vh - 350px)",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Make individual header title bold
            },
            "& .MuiDataGrid-cell": {
              py: 0.5,
              fontSize: "14px",
              fontFamily: "Arial, sans-serif",
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F5F5F5",
              fontWeight: "bold",
              fontSize: "14px",
              color: "#333",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#F5F5F5",
            },
          }}
          disableColumnReorder={false} // Enable column drag-and-drop reordering
          columnBuffer={2} // Optimize column resizing experience
        />
      )}

      {/* Modal */}
      {isModalOpen && (
        <CreateAssessmentModal
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          accounts={accounts?.filter(
            (account) => account.accountNumber !== "INIT00000001"
          )}
          users={users}
          orgCode={organization?.orgcode}
        />
      )}
    </Box>
  );
};

export default WorkloadListPage;
