import React, { useMemo, useState } from "react";
import { Box, IconButton, Divider, Tooltip } from "@mui/material";
import SortIcon from "@mui/icons-material/Sort";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import ComplianceChart from "./ComplianceChart";
import DashboardCard from "../components/DashboardCard";
import { useAccessibleUserAccounts } from "../../../components/Common/HelperComponents/CustomHooks";
import { useOrganizations } from "../../../components/Common/HelperComponents/GetDataFromStore";
import { useComplianceData } from "./useComplianceData";
import ApiLoading from "../components/ApiLoading";
import ApiError from "../components/ApiError";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { NextPlanOutlined } from "@mui/icons-material";

const getColorForCompliance = (name) => {
  const colorMap = {
    "AWS": "#2196f3", // Blue
    "HITRUST": "#f44336", // Red
    "SOC2": "#9e9e9e", // Gray
    "GDPR": "#673ab7", // Deep Purple
    "ISO": "#00bcd4", // Cyan
    "NIST": "#009688", // Teal
    "MITRE": "#795548", // Brown
    "CCPA": "#e81e63", // Pink
    "PCI": "#8bc34a", // Light Green
    "CIS": "#ffc107", // Amber
    "HIPAA": "#ff9800", // Orange
  };

  const complianceGroups = {
    "AWS Well Architected Framework": "AWS",
    "CCPA": "CCPA",
    "CIS AWS Foundations Benchmark": "CIS",
    "CIS AWS Foundations Benchmark v3.0.0": "CIS",
    "CSA CCM": "CSA CCM",
    "CSA CCM v4.02": "CSA CCM",
    "GDPR": "GDPR",
    "EU GDPR": "GDPR",
    "EU GDPR 2016/679": "GDPR",
    "US HIPAA 164": "HIPAA",
    "HITRUST CSF": "HITRUST",
    "HITRUST CSF 11.2": "HITRUST",
    "ISO IEC 27001": "ISO",
    "ISO IEC 27001:2002": "ISO",
    "MITRE ATT&CK Cloud": "MITRE",
    "MITRE ATT&CK Containers": "MITRE",
    "NIST CSF": "NIST",
    "NIST CSF v1.1": "NIST",
    "NIST SP 800-53": "NIST",
    "NIST SP 800-171": "NIST",
    "PCI DSS": "PCI",
    "PCI DSS v4.0": "PCI",
    "AICPA SOC 2": "SOC2",
  };

  // Get the group for the compliance name, then use it to get the color
  const group = complianceGroups[name];
  return colorMap[group] || "#000000"; // Default color if no match
};

const Compliance = () => {
  const history = useHistory();
  const accessibleAccounts = useAccessibleUserAccounts();
  const organizations = useOrganizations();

  const [sortOrder, setSortOrder] = useState("desc");

  const accCodes = useMemo(
    () => accessibleAccounts.map((account) => account.accountCode),
    [accessibleAccounts]
  );
  const orgCode = useMemo(() => organizations?.[0]?.orgcode, [organizations]);

  const { data, loading, error } = useComplianceData(accCodes, orgCode);

  const handleSort = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const sortedData = useMemo(() => {
    return data?.Compliance?.sort((a, b) =>
      sortOrder === "asc"
        ? a.Percentage - b.Percentage
        : b.Percentage - a.Percentage
    );
  }, [data, sortOrder]);

  const handleGoTo = () => {
    console.log("Go-to action clicked");
    history.push("/Compliance");
  };

  const actions = [
    // {
    //   icon: (
    //     <IconButton onClick={handleSort} size="small" sx={{ p: 0, mx: -2.5 }}>
    //       <SortIcon fontSize="small" />
    //     </IconButton>
    //   ),
    //   label: "Sort",
    // },
    // {
    //   icon: (
    //     <Divider
    //       orientation="vertical"
    //       flexItem
    //       sx={{ alignSelf: "center", height: "18px", mx: -2 }} // Adjusted margin
    //     />
    //   ),
    //   label: "Divider",
    // },
    {
      icon: (
        <Tooltip title="Open Detailed View">
          <IconButton onClick={handleGoTo} size="small" sx={{ p: 0, color: "text.secondary", transform: "rotate(-45deg)" }}>
            <NextPlanOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
      label: "Go-to",
    },
  ];

  return (
    <DashboardCard title="Compliance" actions={actions}>
      {loading ? (
        <ApiLoading parameter="Compliance" />
      ) : error ? (
        <ApiError parameter="Compliance" />
      ) : (
        <Box
          sx={{
            width: "100%",
            flexGrow: 1,
            overflowY: "auto",
            pr: 1,
            mb: 1,
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "background.default",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "grey.400",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              backgroundColor: "grey.500",
            },
          }}
        >
          {sortedData?.map((item, index) => (
            <ComplianceChart
              key={index}
              name={item.Name}
              value={item.Percentage}
              color={getColorForCompliance(item.Name)}
            />
          ))}
        </Box>
      )}
    </DashboardCard>
  );
};

export default Compliance;
