import { fork, put, all, call, takeLatest } from "redux-saga/effects";

import { fetchOrgCodesSuccess, fetchOrgCodesFailure } from "./orgScopeSlice";

import { getOrganizationScope } from "../../../helpers/auth_aws_helper";

function* fetchOrgCodes() {
  try {
    const response = yield call(getOrganizationScope);
    console.log("fetchOrgCodesApi response:", response);
    if (response && response.status === 200) {
      const { organizationReferences, msp, mspOrgCode } = response;
      yield put(
        fetchOrgCodesSuccess({
          organizationReferences: organizationReferences || [],
          msp: msp || false,
          mspOrgCode: mspOrgCode || null,
        })
      );
    } else {
      console.log("Fetching Org Codes Error:", response);
      yield put(
        fetchOrgCodesFailure({
          message:
            response?.data?.message ||
            response?.data ||
            "Unexpected error occurred while fetching org codes.",
        })
      );
    }
  } catch (error) {
    if (error.response) {
      yield put(
        fetchOrgCodesFailure({
          message: `Error Code: ${error.response?.status}, Message: ${error.response?.data}`,
        })
      );
    } else if (error.request) {
      yield put(fetchOrgCodesFailure({ message: "No response received" }));
    } else {
      yield put(fetchOrgCodesFailure({ message: error?.message }));
    }
  }
}

function* watchOrgCodes() {
  yield takeLatest("orgScope/fetchOrgCodes", fetchOrgCodes);
}

export function* orgScopeSaga() {
  yield all([fork(watchOrgCodes)]);
}

export default orgScopeSaga;
