import React, { useEffect, useContext, useState, useCallback } from "react";
import { useDispatch, useSelector, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  fetchOrgCodes,
  clearOrgCodesState,
} from "../../store/auth/getOrgScope/orgScopeSlice";
import {
  CircularProgress,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Slide,
  Typography,
  Menu,
  MenuItem,
  AppBar,
  Toolbar,
  Button,
  Chip,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import { OrgContext } from "../../OrgContext";
import logolight from "../../assets/images/Expanded_WhiteOrange_Curve.png";
import AddOrganizationModal from "./AddOrganizationModal";
import { getLicensingData } from "../../store/actions";

const container = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100%",
  backgroundColor: "#F4F6F8",
};

const outerBox = {
  backgroundColor: "white",
  boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.2)",
  borderRadius: "0 0 0.5rem 0.5rem",
  width: "700px",
  height: "80vh",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
};

const contentArea = {
  flexGrow: 1,
  padding: "1rem",
  overflowY: "auto",
};

const tableContainer = {
  marginTop: "1rem",
  borderRadius: "0 0 0.5rem 0.5rem",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
};

const tableHeader = {
  textAlign: "center",
  backgroundColor: "#E0E0E0",
};

const OrgSelection = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoading, orgCodes, errorMessage, msp, mspOrgCode } = useSelector(
    (state) => state.orgScope
  );

  const { setHasSelectedOrganization } = useContext(OrgContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const handleSuccess = useCallback(() => {
    dispatch(fetchOrgCodes()); // Refresh organization list
    handleCloseModal(); // Close the modal
  }, [dispatch]);

  useEffect(() => {
    setHasSelectedOrganization(false);
    dispatch(fetchOrgCodes());
    return () => {
      dispatch(clearOrgCodesState());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && orgCodes.length === 1 && !msp) {
      handleOrganizationClick(orgCodes[0]);
    }
  }, [isLoading, orgCodes, msp]);

  const handleSnackbarClose = () => {
    dispatch(clearOrgCodesState());
  };

  const handleOrganizationClick = (org) => {
    localStorage.setItem("selectedOrganization", JSON.stringify(org));
    localStorage.setItem("hasSelectedOrganization", true);
    setHasSelectedOrganization(true);
    history.push("/dashboard");
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  // Sort orgCodes: Primary (mspOrgCode) first
  const sortedOrgCodes = Array.isArray(orgCodes)
    ? [...orgCodes].sort((a, b) => (a.orgCode === mspOrgCode ? -1 : 1))
    : [];

  return (
    <div style={container}>
      <Slide direction="down" in={true} mountOnEnter unmountOnExit>
        <div style={outerBox}>
          {/* Title Bar with Logo and Logout */}
          <AppBar position="static" style={{ backgroundColor: "#193A6F" }}>
            <Toolbar style={{ justifyContent: "space-between" }}>
              <img
                src={logolight}
                alt="Logo"
                style={{ height: "40px", cursor: "pointer" }}
                onClick={() => history.push("/")}
              />
              <div>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>Logout</MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          {/* Content Area */}
          <div style={contentArea}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} style={{ color: "#F98125" }} />
              ) : (
                msp && (
                  <Button
                    variant="contained"
                    style={{ backgroundColor: "#F98125" }}
                    startIcon={<AddIcon />}
                    onClick={handleOpenModal}
                  >
                    Invite Sub Organization
                  </Button>
                )
              )}
            </div>

            {isLoading ? (
              <div style={{ textAlign: "center", margin: "20px" }}>
                <CircularProgress />
              </div>
            ) : (
              <TableContainer component={Paper} style={tableContainer}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2} style={tableHeader}>
                        <Typography variant="h6" fontWeight="bold">
                          Organization List
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedOrgCodes.length > 0 ? (
                      sortedOrgCodes.map((org) => (
                        <TableRow
                          key={org.orgCode}
                          hover
                          style={{
                            transition: "background-color 0.3s",
                            cursor: "pointer",
                            borderBottom: "1px solid #E0E0E0",
                          }}
                        >
                          <TableCell align="center" style={{ padding: "16px" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "8px",
                              }}
                            >
                              <Typography variant="body1" fontWeight="bold">
                                {org.orgName}
                              </Typography>
                              {org.orgCode === mspOrgCode && (
                                <span
                                  style={{
                                    display: "inline-block",
                                    fontSize: "12px",
                                    color: "#1976D2",
                                    backgroundColor: "#E3F2FD",
                                    padding: "2px 8px",
                                    borderRadius: "12px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Primary
                                </span>
                              )}
                            </div>
                          </TableCell>
                          <TableCell align="center" style={{ padding: "16px" }}>
                            <IconButton
                              color="primary"
                              onClick={() => handleOrganizationClick(org)}
                              style={{
                                borderRadius: "50%",
                                border: "1px solid #1976D2",
                                padding: "6px",
                              }}
                            >
                              <LoginIcon style={{ fontSize: "20px" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{ textAlign: "center", padding: "20px" }}
                        >
                          <Typography
                            variant="body1"
                            style={{ color: "#757575" }}
                          >
                            No organizations available.
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Snackbar
              open={Boolean(errorMessage)}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              message={errorMessage}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            />
          </div>
        </div>
      </Slide>

      {/* Add Organization Modal */}
      <AddOrganizationModal
        open={isModalOpen}
        onClose={handleCloseModal}
        onSuccess={handleSuccess}
        mspOrgCode={mspOrgCode}
      />
    </div>
  );
};

export default OrgSelection;
