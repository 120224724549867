import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Tooltip,
  IconButton,
  Popover,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import InfoIcon from "@mui/icons-material/Info";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import {
  getCisPosture,
  postCisScan,
  clearAllCisMessages,
} from "../../store/cisWafr/cisWafrSlice";
import { useOrganization } from "../../components/Common/HelperComponents/GetDataFromStore";
import { enqueueSnackbar } from "notistack";
import { Visibility } from "@material-ui/icons";

const CisWAFR = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [tooltipData, setTooltipData] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const dispatch = useDispatch();
  const history = useHistory();
  const organization = useOrganization();
  const orgCode = organization?.orgcode;

  // Get API response state from Redux
  const {
    cisPosture,
    isGettingCisPosture,
    getCisPostureError,
    postCisScanMessage,
    postCisScanError,
  } = useSelector((state) => state.cisWafr);
  console.log("🚀 ~ CisWAFR ~ postCisScanError:", postCisScanError);
  console.log("🚀 ~ CisWAFR ~ postCisScanMessage:", postCisScanMessage);

  useEffect(() => {
    if (orgCode) {
      dispatch(getCisPosture({ orgCode }));
    }
  }, [dispatch, orgCode]);

  const handleRetry = () => {
    dispatch(getCisPosture({ orgCode }));
  };

  useEffect(() => {
    if (postCisScanMessage) {
      // Show success notification
      enqueueSnackbar(postCisScanMessage, { variant: "success" });
    }
    if (postCisScanError) {
      enqueueSnackbar(postCisScanError, { variant: "error" });
    }
    if (postCisScanMessage || postCisScanError) {
      dispatch(clearAllCisMessages());
    }
  }, [postCisScanMessage, postCisScanError, dispatch]);

  const handleRunClick = (accountNumber) => {
    dispatch(postCisScan({ orgCode, accountNumber }));
  };

  const handleNavigateToDetails = (accountNumber) => {
    history.push(`/CISWAF/${accountNumber}`);
  };

  // Transform cisPosture object into DataGrid-compatible rows
  const rows =
    cisPosture &&
    Object.keys(cisPosture).map((key) => ({
      id: key,
      ...cisPosture[key],
    }));

  const columns = [
    {
      field: "cisStandard",
      headerName: "CIS Standard",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "accountNumber",
      headerName: "Account Number",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "controlsIds",
      headerName: "Assessed Controls",
      flex: 1,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.controlsIds || "N/A",
    },
    {
      field: "controlStatus",
      headerName: "Control Status",
      flex: 2,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const controlStatus = params.row.controlStatus;

        if (!controlStatus) {
          return <Typography>N/A</Typography>;
        }

        const {
          successes,
          successPercent,
          violations,
          violationPercent,
          errors,
          errorPercent,
        } = controlStatus;

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {/* Progress Bars */}
            <Box
              sx={{
                position: "relative",
                width: "150px",
                height: "16px",
                borderRadius: "8px",
                backgroundColor: "#e0e0e0",
                overflow: "hidden",
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Success */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${successPercent || 0}%`,
                  backgroundColor: "green",
                  zIndex: 1,
                }}
              />
              {successPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${successPercent / 2}%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {successPercent}%
                </Typography>
              )}
              {/* Violations */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${violationPercent || 0}%`,
                  left: `${successPercent || 0}%`,
                  backgroundColor: "orange",
                  zIndex: 1,
                }}
              />
              {violationPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${successPercent + violationPercent / 2}%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {violationPercent}%
                </Typography>
              )}
              {/* Errors */}
              <Box
                sx={{
                  position: "absolute",
                  height: "100%",
                  width: `${errorPercent || 0}%`,
                  left: `${(successPercent || 0) + (violationPercent || 0)}%`,
                  backgroundColor: "red",
                  zIndex: 1,
                }}
              />
              {errorPercent > 0 && (
                <Typography
                  sx={{
                    position: "absolute",
                    left: `${
                      successPercent + violationPercent + errorPercent / 2
                    }%`,
                    color: "white",
                    fontSize: "10px",
                    zIndex: 2,
                    fontWeight: "bold",
                  }}
                >
                  {errorPercent}%
                </Typography>
              )}
            </Box>

            {/* Tooltip */}
            <InfoIcon
              onMouseEnter={(e) => {
                setAnchorEl(e.currentTarget);
                setTooltipData({
                  successes,
                  successPercent,
                  violations,
                  violationPercent,
                  errors,
                  errorPercent,
                });
              }}
              onMouseLeave={() => {
                setAnchorEl(null);
                setTooltipData(null);
              }}
              sx={{
                color: "#1976d2",
                fontSize: "20px",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "lastRunDate",
      headerName: "Last Run Date [UTC]",
      flex: 1.5,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.lastRunDate || "N/A",
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.8,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Box display="flex" gap="8px">
          <Tooltip title="Start Assessment">
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation(); // Prevent unnecessary event bubbling
                handleRunClick(params.row.accountNumber);
              }}
            >
              <PlayArrowIcon sx={{ color: "#1976d2", fontSize: "24px" }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="View Details">
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                history.push(`/CISWAF/${params.row.accountNumber}`);
              }}
            >
              <Visibility sx={{ color: "#1976d2", fontSize: "24px" }} />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
  ];

  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{
        justifyContent: "space-between",
        padding: "0.5rem 1rem",
      }}
    >
      <Box display="flex" alignItems="center">
        <GridToolbarQuickFilter
          placeholder="Search..."
          sx={{
            "& input": {
              borderRadius: "8px",
              backgroundColor: "#F1F1F1",
              padding: "8px 12px",
            },
          }}
        />
      </Box>
    </GridToolbarContainer>
  );

  return (
    <Box
      className="page-content"
      sx={{
        padding: "1.5rem 2rem",
        mt: "5rem",
        backgroundColor: "#F9F9F9",
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" fontWeight="bold" color="#333">
          Workload Assessments
        </Typography>
      </Box>

      {isGettingCisPosture ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="50vh"
        >
          <CircularProgress color="primary" />
        </Box>
      ) : getCisPostureError ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            backgroundColor: "#fef8f8",
            border: "1px solid #f44336",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <ErrorOutlineIcon sx={{ fontSize: 48, color: "#f44336", mb: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#f44336"
            sx={{ mb: 1 }}
          >
            Something Went Wrong
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            {getCisPostureError}
          </Typography>
          <Button
            variant="outlined"
            startIcon={<RefreshIcon />}
            onClick={handleRetry}
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              borderColor: "#1976d2",
              color: "#1976d2",
              "&:hover": {
                borderColor: "#1565c0",
                backgroundColor: "#e3f2fd",
              },
            }}
          >
            Retry
          </Button>
        </Box>
      ) : rows?.length === 0 ? (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "50vh",
            backgroundColor: "#fef8f8",
            border: "1px solid #1976d2",
            borderRadius: "8px",
            padding: "2rem",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h6"
            fontWeight="bold"
            color="#1976d2"
            sx={{ mb: 1 }}
          >
            No Account Onboarded
          </Typography>
          <Typography variant="body1" color="#333" sx={{ mb: 2 }}>
            Please go to the configuration page and add an account to get
            started.
          </Typography>
        </Box>
      ) : (
        <DataGrid
          rows={rows || []}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          autoHeight
          components={{ Toolbar: CustomToolbar }}
          loading={isGettingCisPosture}
          rowHeight={40}
          sx={{
            backgroundColor: "white",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            maxHeight: "calc(100vh - 350px)",
            "& .MuiDataGrid-columnHeaderTitle": {
              fontWeight: "bold", // Make individual header title bold
            },
            "& .MuiDataGrid-cell": {
              py: 0.5,
              fontSize: "14px",
              fontFamily: "Arial, sans-serif",
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#F5F5F5",
              fontWeight: "bold",
              fontSize: "14px",
              color: "#333",
            },
            "& .MuiDataGrid-footerContainer": {
              backgroundColor: "#F5F5F5",
            },
          }}
        />
      )}

      {/* Tooltip Popover */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            padding: "8px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        {tooltipData && (
          <Box>
            <Typography variant="body2" color="green" fontWeight="bold">
              Successes: {tooltipData.successes} (
              {tooltipData.successPercent || 0}%)
            </Typography>
            <Typography variant="body2" color="orange" fontWeight="bold">
              Violations: {tooltipData.violations} (
              {tooltipData.violationPercent || 0}%)
            </Typography>
          </Box>
        )}
      </Popover>
    </Box>
  );
};

export default CisWAFR;
