import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import SectionQuestionsPanel from "./SectionQuestionsPanel";
import SectionQuestionsDetailsPanel from "./SectionQuestionsDetailsPanel";

const SectionDetails = ({sectionQuestionLabel, sectionQuestionList, sectionQuestionDetailList, selectedQuestion, setSelectedQuestion, orgCode, accCode, accNumber }) => {
  return (
    <Box
      display="flex"
      alignItems="stretch"
      gap="0.5rem"
      pt={1}
      pb={1}
      sx={{ height: "100%", overflow: "hidden" }}
    >
      {/* Questions Panel */}
      <Paper
        elevation={3}
        sx={{
          flex: "0 0 20%",
          padding: "0.25rem",
          backgroundColor: "white",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SectionQuestionsPanel
          sectionQuestionLabel={sectionQuestionLabel}
          sectionQuestionList={sectionQuestionList}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
        />
      </Paper>

      {/* Question Detail Panel */}
      <Paper
        elevation={3}
        sx={{
          flex: "1",
          padding: "1rem",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SectionQuestionsDetailsPanel
          sectionQuestionDetailList={sectionQuestionDetailList}
          selectedQuestion={selectedQuestion}
          sectionQuestionLabel={sectionQuestionLabel}
          key={sectionQuestionDetailList[selectedQuestion].questionTitle}
          orgCode={orgCode}
          accCode={accCode}
          accNumber={accNumber}
        />
      </Paper>
    </Box>
  );
};

export default SectionDetails;
