import React from "react";
import {
  Box,
  Switch,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";

const notApplicableOptions = [
  {
    value: "OUT_OF_SCOPE",
    label: "Out of scope",
  },
  {
    value: "BUSINESS_PRIORITIES",
    label: "Business Priorities",
  },
  {
    value: "ARCHITECTURE_CONSTRAINTS",
    label: "Architecture Constraints",
  },
  {
    value: "OTHER",
    label: "Other",
  },
  {
    value: "NONE",
    label: "None",
  },
];
const ApplicabilityOption = ({
  questionId,
  notApplicable,
  setNotApplicable,
  reason,
  setReason,
  children,
  parentNotApplicable = false,
}) => {
  const handleSwitchChange = (event) => {
    const isChecked = event.target.checked;
    setNotApplicable(isChecked);

    // Reset dropdown value if switch is disabled
    if (!isChecked) {
      setReason("");
    }
  };

  const handleDropdownChange = (event) => {
    const selectedReason = event.target.value;
    setReason(selectedReason);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      {/* Not Applicable Switch */}
      <Box>
        <span style={{ fontSize: "0.75rem", marginRight: "0.5rem" }}>
          Not Applicable:
        </span>
        <Switch
          size="small"
          checked={notApplicable}
          onChange={handleSwitchChange}
          disabled={parentNotApplicable}
        />
        {/* Dropdown */}
        {notApplicable && (
          <>
            {/* Dropdown for Reasons */}
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel
                id={`reason-select-label-${questionId}`}
                sx={{ fontSize: "0.6rem" }}
              >
                Reason
              </InputLabel>
              <Select
                labelId={`reason-select-label-${questionId}`}
                label="Reason"
                value={reason}
                onChange={handleDropdownChange}
                sx={{ fontSize: "0.6rem" }}
                disabled={parentNotApplicable}
              >
                {notApplicableOptions.map((naOption) => (
                  <MenuItem
                    value={naOption.value}
                    key={naOption.value}
                    sx={{ fontSize: "0.6rem" }}
                  >
                    {naOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </Box>
      {/* Notes */}
      {notApplicable && <Box>{children}</Box>}
    </Box>
  );
};

export default ApplicabilityOption;
