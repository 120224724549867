import { createSlice } from "@reduxjs/toolkit";

export const wafrQuestionnaireSlice = createSlice({
  name: "wafrQuestionnaire",
  initialState: {
    isGettingWafrQuestionnaire: false,
    getWafrQuestionnaireMessage: null,
    getWafrQuestionnaireError: null,
    getWafrQuestionnairePayload: null,
    wafrQuestionnaire: null,

    // Initial state for getAssessmentList
    isGettingAssessmentList: false,
    getAssessmentListMessage: null,
    getAssessmentListError: null,
    assessmentList: null,

    // Update answer
    isUpdatingWafrAnswer: false,
    updateWafrAnswerMessage: null,
    updateWafrAnswerError: null,
    updateWafrAnswerPayload: null,

    // Creating workload
    isPostingWafrAssessment: false,
    postWafrAssessmentMessage: null,
    postWafrAssessmentError: null,
    postWafrAssessmentPayload: null,
  },
  reducers: {
    getWafrQuestionnaire: (state, action) => {
      state.isGettingWafrQuestionnaire = true;
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = null;
      state.getWafrQuestionnairePayload = action.payload;
      state.wafrQuestionnaire = null;
    },
    getWafrQuestionnaireSuccessful: (state, action) => {
      state.isGettingWafrQuestionnaire = false;
      state.wafrQuestionnaire = action.payload;
      state.getWafrQuestionnairePayload = null;
      state.getWafrQuestionnaireMessage =
        "WAFR Questionnaire retrieved Successfully!";
      state.getWafrQuestionnaireError = null;
    },
    getWafrQuestionnaireFailure: (state, action) => {
      state.isGettingWafrQuestionnaire = false;
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = action.payload.message;
      state.getWafrQuestionnairePayload = null;
    },
    clearAllWafrQuestionnaireMessages: (state, action) => {},
    clearAllWafrQuestionnaireMessagesSuccessful: (state, action) => {
      state.getWafrQuestionnaireMessage = null;
      state.getWafrQuestionnaireError = null;

      state.updateWafrAnswerError = null;
      state.updateWafrAnswerMessage = null;

      state.postWafrAssessmentMessage = null;
      state.postWafrAssessmentError = null;
    },

    updateWafrAnswer: (state, action) => {
      state.isUpdatingWafrAnswer = true;
      state.updateWafrAnswerMessage = null;
      state.updateWafrAnswerError = null;
      state.updateWafrAnswerPayload = action.payload;
    },
    updateWafrAnswerSuccessful: (state, action) => {
      state.isUpdatingWafrAnswer = false;
      state.updateWafrAnswerMessage = "Answers were updated successfully";
      state.updateWafrAnswerPayload = null;
    },
    updateWafrAnswerFailure: (state, action) => {
      state.isUpdatingWafrAnswer = false;
      state.updateWafrAnswerError = action.payload.message;
      state.updateWafrAnswerPayload = null;
    },

    // Reducers for getAssessmentList
    getAssessmentList: (state) => {
      state.isGettingAssessmentList = true;
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = null;
      state.assessmentList = null;
    },
    getAssessmentListSuccessful: (state, action) => {
      state.isGettingAssessmentList = false;
      state.assessmentList = action.payload;
      state.getAssessmentListMessage =
        "Assessment List retrieved Successfully!";
      state.getAssessmentListError = null;
    },
    getAssessmentListFailure: (state, action) => {
      state.isGettingAssessmentList = false;
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = action.payload.message;
    },
    clearAllAssessmentMessages: (state) => {
      state.getAssessmentListMessage = null;
      state.getAssessmentListError = null;
    },

    postWafrAssessment: (state, action) => {
      state.isPostingWafrAssessment = true;
      state.postWafrAssessmentMessage = null;
      state.postWafrAssessmentError = null;
      state.postWafrAssessmentPayload = action.payload;
    },
    postWafrAssessmentSuccessful: (state, action) => {
      state.isPostingWafrAssessment = false;
      state.postWafrAssessmentMessage = action.payload;
      state.postWafrAssessmentPayload = null;
    },
    postWafrAssessmentFailure: (state, action) => {
      state.isPostingWafrAssessment = false;
      state.postWafrAssessmentError = action.payload.message;
      state.postWafrAssessmentPayload = null;
    },
  },
});

export const {
  getWafrQuestionnaire,
  getWafrQuestionnaireSuccessful,
  getWafrQuestionnaireFailure,
  clearAllWafrQuestionnaireMessages,
  clearAllWafrQuestionnaireMessagesSuccessful,

  getAssessmentList,
  getAssessmentListSuccessful,
  getAssessmentListFailure,
  clearAllAssessmentMessages,

  updateWafrAnswer,
  updateWafrAnswerSuccessful,
  updateWafrAnswerFailure,

  postWafrAssessment,
  postWafrAssessmentSuccessful,
  postWafrAssessmentFailure,
} = wafrQuestionnaireSlice.actions;

export default wafrQuestionnaireSlice.reducer;
