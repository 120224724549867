import React, { Component } from "react";
import Creatable from "react-select/creatable";
import {
  Button,
  Modal,
  ModalFooter,
  ModalBody,
  Row,
  Col,
  Card,
  CardBody,
  TabContent,
  TabPane,
  FormGroup,
  Container,
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import awsLogo from "../../assets/images/aws_logo.png";
import { connect } from "react-redux";
import {
  registerOrgAccount,
  registerOrgAccountSuccessful,
  getListOfOrgsAndAccts,
  getAwsAcc,
  clearAllOrgAccountMessages,
} from "../../store/actions";
import {
  getReleaseDetailsRequest,
  clearAllReleaseMessages,
} from "../../store/actions";

import { countryList } from "../../utilities/countries";
import { Tooltip } from "@material-ui/core";
import { Snackbar, Link } from "@mui/material";
import { Alert } from "@material-ui/lab";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

import {
  verifyAccountStatusRequest,
  clearAllScanServiceMessages,
} from "../../store/scanService/scanServiceSlice";

import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { v4 as uuidv4 } from "uuid"; // for uuid version 4
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { SnackbarProvider, enqueueSnackbar } from "notistack";

class OrgAccount extends Component {
  constructor(props) {
    super(props);
    this.abortController = null;
    this.state = {
      breadcrumbItems: [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Register an AWS account", link: "#" },
      ],
      activeTab: 1,
      orgId: "",
      orgName: "",
      orgCode: "",
      accountNumber: "",
      accountName: "",
      accountCode: "",
      urlLink: "",
      location: "",
      accountType: "AWS",
      address: `${props.organizations}`
        ? `${props.organizations[0].orgaddress}`
        : "",
      city: `${props.organizations}` ? `${props.organizations[0].orgcity}` : "",
      state: `${props.organizations}`
        ? `${props.organizations[0].orgstate}`
        : "",
      zip: `${props.organizations}` ? `${props.organizations[0].orgzip}` : "",
      country: `${props.organizations}`
        ? `${props.organizations[0].orgcountry}`
        : "",
      email: "",
      emailDomain: "",
      phonenumber: "",
      phonenumberError: null,
      eula: "blah",
      currentStepError: false,
      accountResourceGroups: [],
      selectedOption: [],
      defaultOptions: [
        { value: "H.R.", label: "H.R." },
        { value: "Finance", label: "Finance" },
        { value: "Engineering", label: "Engineering" },
        { value: "Unassigned", label: "Unassigned" },
      ],
      roleName: "CloudCatcherTrustRole",
      externalId: "",
      copy: false,
      awsAccNumber: "placeholder",
      showCopyLinkHelp: false,

      //added for MUI Stepper support
      step: 0,
      modal_addons: false,

      verifyAccount: null, // 1 means verified and 0 means not verified
      verifyAccountError: false, // true means error in verification
      verifyAccountSuccess: false, //true means verified and false means not

      registerAccountError: false, // Error for display error while creating account
    };
    this.linkTextAreaRef = React.createRef();
  }

  resetState = () => {
    this.setState({
      orgId: "",
      orgName: "",
      orgCode: "",
      accountNumber: "",
      accountName: "",
      accountCode: "",
      urlLink: "",
      location: "",
      // city: "",
      email: "",
      emailDomain: "",
      phonenumber: "",
      phonenumberError: null,
      currentStepError: false,
      accountResourceGroups: [],
      selectedOption: [],
      showCopyLinkHelp: false,
      step: 0,
      modal_addons: false,

      verifyAccount: null,
      verifyAccountError: false,
      verifyAccountSuccess: false,

      registerAccountError: false,
    });
  };

  closeAcctSuccesmsg = () => {
    this.props.newAccountAdded(this.props.accounts);
    this.resetState();
    // this.props.resetOrgAccountStatus();
    // this.props.clearAllOrgAccountMessages();
    this.props.handleAddAccountsModal();
  };

  handleManualPairingGuide = async (e) => {
    e.stopPropagation();
    this.props.getReleaseDetails(
      encodeURIComponent(
        "configurations/Step-by-Step Guide to Pair New Account.pdf"
      )
    );
  };

  handleResourceExplorerGuide = async (e) => {
    e.stopPropagation();
    this.props.getReleaseDetails(process.env.REACT_APP_RESOURCE_EXPLORER_MANUAL_GUIDE);
  };

  handleClickExternalId = () => {
    navigator.clipboard.writeText(this.state.externalId);
    this.setState({ copy: true });
    setTimeout(() => this.setState({ copy: false }), 3000);
  };

  componentDidMount() {
    console.log("--=== componentDidMount in orgAccount", this.props);
    if (this.props.organizations === null || this.props.accounts === null) {
      console.log(
        "--=== componentDidMount, calling this.props.getListOfOrgsAndAccts()"
      );
      this.props.getListOfOrgsAndAccts();
    }
    if (this.props.aws === null) {
      console.log("--=== componentDidMount, calling this.props.getAwsAcc()");
      this.props.getAwsAcc();
    }
    if (this.state.externalId === null || this.state.externalId === "") {
      const newUuid = uuidv4();
      this.setState({ externalId: newUuid });
      console.log("--=== componentDidMount, generated UUID = ", newUuid);
    }

    this.abortController = new AbortController();
  }

  componentWillUnmount() {
    console.log("--=== componentWillUnmount ");
    //Uncomment the following line to reset the state
    this.resetState();
    console.log("clearing all scanService messages...");
    this.props.clearAllScanServiceMessages();

    if (this.abortController) {
      this.abortController.abort();
    }
  }

  componentDidUpdate(prevProps) {
    // console.log("--=== componentDidUpdate ", this.props);
    if (typeof this.props.account === "string") {
      this.toggleTab(this.state.activeTab);
    }

    if (
      this.props.reg_acct_error &&
      this.props.reg_acct_error !== prevProps.reg_acct_error
    ) {
      this.setState({ registerAccountError: true });
      setTimeout(() => this.setState({ registerAccountError: false }), 5000);
    }
    
    if (
      this.props?.organizations[0]?.orgbillingemail &&
      this.state.emailDomain === ""
    ) {
      this.setState({
        emailDomain:
          "@" + this.props.organizations[0].orgbillingemail.split("@").pop(),
      });
    }

    if (this.props.releaseDetails) {
      if (this.props.releaseDetails !== null) {
        const newTab = window.open(this.props.releaseDetails, "_blank");
        newTab.focus();
        this.props.clearAllReleaseMessages();
      }
    }

    // Handle response of verifyAccountStatus
    if (this.props?.accountStatusResult) {
      if (!this.state.verifyAccountSuccess) {
        this.setState({
          verifyAccountSuccess: true,
          verifyAccountError: false,
        });
      }
    } else if (this.props?.accountStatusError) {
      if (!this.state.verifyAccountError) {
        this.setState({
          verifyAccountSuccess: false,
          verifyAccountError: true,
        });
      }
    }

    // Open AddOns Modal if account registration was successful
    // if (this.props.registerOrgAccountMessage) {
    //   let accountNumber = this.state.accountNumber;
    //   // if (!this.state.modal_addons && this.state.accountNumber) {
    //   //   this.setState({ modal_addons: true });
    //   // }
    //   this.props.newAccountAdded(this.props.accounts);
    //   this.resetState();
    //   // this.props.resetOrgAccountStatus();
    //   this.props.clearAllOrgAccountMessages();
    //   this.props.handleAddAccountsModal("ACCOUNT_CREATED", accountNumber);
    // }
  }

  verifyPairedAccountStatus = async () => {
    //Uncomment the following for testing purposes
    // this.setState({
    //   // verifyAccountError: true,
    //     verifyAccountSuccess: true,
    // });
    // return;
    //Uncomment the above for testing purposes

    this.props.verifyAccountStatus(
      this.state.accountNumber,
      this.state.roleName,
      this.state.externalId
    );
  };

  isAccountVerified = () => {
    return this.state.verifyAccountSuccess;
  };

  static getDerivedStateFromProps(props, state) {
    if (props.accounts) {
      const id = props.accounts.map((acct) => {
        return acct.orgId;
      });
      return { orgId: parseInt(id.toString()) };
    }

    return {
      ...state,
    };
  }

  toggleTab = (tab) => {
    if (this.state.activeTab && this.state.activeTab !== tab) {
      if (tab >= 1 && tab <= 2) {
        this.setState({
          activeTab: tab,
        });
      }
    }
  };

  handlePhoneChange = (value) => {
    this.setState({ phonenumber: value });
  };

  handlePhoneBlur = () => {
    let isInvalidResult;
    if (!this.state.phonenumber) {
      isInvalidResult = true;
    } else {
      isInvalidResult = !isPossiblePhoneNumber(this.state.phonenumber);
    }
    if (isInvalidResult) {
      this.setState({ phonenumberError: "Please enter a valid phone number" });
    } else {
      this.setState({ phonenumberError: null });
    }
  };

  handleInputChange = (e) => {
    if (e.target.name === "phonenumber") {
    } else {
      //Below If is for once accountNumber is changed, both (1) Verify Pairing and (2) Check Asset Count should be done again.
      if (e.target.name === "accountNumber") {
        this.setState({
          [e.target.name]: e.target.value,
          verifyAccountSuccess: null,
          // assetCountSuccess: null,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  handleSelectChange = (selectedOption) => {
    if (selectedOption === null) {
      this.setState({ accountResourceGroups: ["Default"] });
    } else {
      this.setState({
        selectedOption,
        accountResourceGroups: [selectedOption.value],
      });
    }
  };

  handleCheckboxChange = () => {
    this.setState({
      termsAndConditionsAgreement: !this.state.termsAndConditionsAgreement,
    });
  };

  handleSubmit = () => {
    //Convert user selection of resource groups (array of strings) to array of objects
    let resourceGroups = this.state.accountResourceGroups.length
      ? this.state.accountResourceGroups.map((str) => ({ resourcename: str }))
      : [{ resourcename: "Default" }];

    let accountRequest = {
      orgId: this.state.orgId,
      accountNumber: this.state.accountNumber,
      accountName: this.state.accountName,
      accountCode: this.state.accountCode,
      urlLink: this.state.urlLink,
      accountType: this.state.accountType,
      address: this.state.address,
      city: this.state.city,
      state: this.state.state,
      zip: this.state.zip,
      country: this.state.country,
      email: this.state.email + this.state.emailDomain,
      phonenumber: this.state.phonenumber,
      eula: this.state.eula,
      accountResourceGroups: resourceGroups,
      iamrole: this.state.roleName,
      externalId: this.state.externalId,
    };
    // Comment API call
    this.props.registerOrgAccount(accountRequest);
    console.log("accountRequest = ", accountRequest);
  };


  handleAccountStep = () => {
    //return true;
    const {
      accountNumber,
      accountName,
      // location,
      accountType,
      address,
      city,
      zip,
      country,
      email,
      roleName,
    } = this.state;
    if (
      accountNumber !== "" &&
      accountName !== "" &&
      // location !== "" &&
      accountType !== "" &&
      address !== "" &&
      city !== "" &&
      zip !== "" &&
      country !== "" &&
      email !== "" &&
      roleName !== ""
    ) {
      this.setState({ currentStepError: false });
      return true;
    } else {
      this.setState({ currentStepError: true });
      return false;
    }
  };

  redirect = () => {
    this.props.history.push("/dashboard");
  };

  pertformCopyBtnUiChanges = (textAreaEl) => {
    document.getElementById("clickToCopyBtnId").textContent = "Copied";
    setTimeout(() => {
      document.getElementById("clickToCopyBtnId").textContent = "Click to Copy";
      textAreaEl.focus();
      textAreaEl.selectionStart = textAreaEl.selectionEnd = 0;
    }, 3000);
  };

  handleClicktoCopy = () => {
    // console.log("--== handleClicktoCopy ", this.linkTextAreaRef);
    if (this.linkTextAreaRef && this.linkTextAreaRef.current) {
      const tag = this.linkTextAreaRef.current;
      tag.select();
      if (navigator && navigator.clipboard) {
        navigator.clipboard.writeText(tag.value);
        this.pertformCopyBtnUiChanges(tag);
      } else {
        document.execCommand("copy");
        this.pertformCopyBtnUiChanges(tag);
      }
    }
  };

  handleNext = async () => {
    if (this.state.step === 0) {
      if (!this.handleAccountStep()) return;
    } 
    //Call form submission code
    if (this.state.step === 0) {
      this.handleSubmit();
    }
  };

  isAccountInvalid = () => {
    const regex = /^[^ @]+$/;
    let isPhoneNumberInvalid;
    if (!this.state.phonenumber) {
      isPhoneNumberInvalid = true;
    } else {
      isPhoneNumberInvalid = !isPossiblePhoneNumber(this.state.phonenumber);
    }
    const isInvalidItems = [
      {
        field: "email",
        isInvalid:
          (this.state.email && this.state.email.length === 0) ||
          !regex.test(String(this.state.email).toLowerCase()),
      },
      {
        field: "accountNumber",
        isInvalid:
          this.state.accountNumber.length !== 12 ||
          !/^[0-9]+$/.test(this.state.accountNumber)
            ? true
            : false,
      },
      {
        field: "accountName",
        isInvalid: this.state.accountName.length === 0 ? true : false,
      },
      {
        field: "phonenumber",
        value: this.state.phonenumber,
        isInvalid: isPhoneNumberInvalid,
      },
      {
        field: "address",
        isInvalid: this.state.address.length === 0 ? true : false,
      },
      {
        field: "city",
        isInvalid:
          this.state.city.length === 0 || !/^[A-Za-z]+$/.test(this.state.city)
            ? true
            : false,
      },
      { field: "accountResourceGroups", isInvalid: false },
      {
        field: "roleName",
        isInvalid: this.state.roleName.length === 0 ? true : false,
      },
    ];

    return isInvalidItems.filter((item) => item.isInvalid).length > 0;
  };

  isCurrentStepInvalid = () => {
    // return false;
    if (this.state.step === 0) {
      //return false;
      return (
        this.isAccountInvalid() || !this.state.verifyAccountSuccess
      );
    } 
  };

  render() {
    console.log("Rerender Props: ============", this.props);
    console.log("accountStatusLoading: ", this.props.accountStatusLoading);
    console.log("accountStatusResult: ", this.props.accountStatusResult);
    console.log("accountStatusError: ", this.props.accountStatusError);
    console.log("Rerender Props: ============");

    let showTextArea = false;

    return (
      <React.Fragment>
        <SnackbarProvider />
        <div>
          <Container fluid={true}>
            <Row>
              <Col sm="12" md="12">
                <Card>
                  <CardBody>
                    {this.state.step === 0 && (
                      <div
                        id="basic-pills-wizard"
                        className="twitter-bs-wizard"
                      >
                        <TabContent
                          activeTab={this.state.activeTab}
                          className="twitter-bs-wizard-tab-content"
                        >
                          <TabPane tabId={1}>
                            <div className="row">
                              <div className="col-sm-12 col-md-6">
                                <AvForm className="form-horizontal">
                                  <div>
                                    <div>
                                      <p>
                                        <strong style={{ fontSize: "1.2em" }}>
                                          Step 1:
                                        </strong>{" "}
                                        <span style={{ fontSize: "1em" }}>
                                          Enter Account Details
                                        </span>
                                      </p>
                                    </div>
                                    {this.state.currentStepError && (
                                      <small className="alert-danger header">
                                        {" "}
                                        Please fill all required fields
                                      </small>
                                    )}
                                    {this.props.reg_acct_error &&
                                    typeof this.props.reg_acct_error ===
                                      "string" ? (
                                      <small className="alert-danger header">
                                        {this.props.reg_acct_error}
                                      </small>
                                    ) : null}
                                    <hr />
                                  </div>
                                  <div className="d-flex">
                                    <label>
                                      <input
                                        className="radio-img"
                                        type="radio"
                                        name="accountType"
                                        value="AWS"
                                        onChange={this.handleInputChange}
                                        defaultChecked
                                      />
                                      <img
                                        src={awsLogo}
                                        height="40"
                                        alt="AWS account type"
                                      />
                                    </label>
                                    <FormGroup className="mb-3 w-100">
                                      <AvField
                                        type="select"
                                        name="orgName"
                                        value={this.state.orgName}
                                        onChange={this.handleInputChange}
                                        disabled
                                      >
                                        {this.props.organizations &&
                                        this.props.organizations.length !== 0
                                          ? this.props.organizations.map(
                                              (org) => {
                                                return (
                                                  <option key={org.orgId}>
                                                    {org.orgname}
                                                  </option>
                                                );
                                              }
                                            )
                                          : null}
                                      </AvField>
                                    </FormGroup>
                                  </div>
                                  <Row>
                                    <Col lg="12">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="accountNumber"
                                          value={this.state.accountNumber}
                                          placeholder="Account Number*"
                                          type="text"
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.accountNumber === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          errorMessage="Enter account number"
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage:
                                                "Account number is required",
                                            },
                                            pattern: {
                                              value: "^[0-9]+$",
                                              errorMessage:
                                                "Account number must include only numbers",
                                            },
                                            minLength: {
                                              value: 12,
                                              errorMessage:
                                                "Account number must be minimum 12 digits",
                                            },
                                            maxLength: {
                                              value: 12,
                                              errorMessage:
                                                "Account number cannot be more than 12 digits",
                                            },
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="accountName"
                                          value={this.state.accountName}
                                          placeholder="Account Name*"
                                          type="text"
                                          errorMessage="Enter account name"
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.accountName === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage:
                                                "Account name is required",
                                            },
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="12">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="address"
                                          value={this.state.address}
                                          placeholder="Address*"
                                          type="text"
                                          errorMessage="Enter address"
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.address === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage:
                                                "Address is required",
                                            },
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="4">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          type="select"
                                          name="country"
                                          value={this.state.country}
                                          onChange={this.handleInputChange}
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.country === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                        >
                                          {countryList.map((country) => {
                                            return <option>{country}</option>;
                                          })}
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="city"
                                          value={this.state.city}
                                          placeholder="City*"
                                          type="text"
                                          errorMessage="Enter city"
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.city === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage: "City is required",
                                            },
                                            pattern: {
                                              value: "^[A-Za-z]+$",
                                              errorMessage:
                                                "Please enter a valid city",
                                            },
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="zip"
                                          value={this.state.zip}
                                          placeholder="Zip/Postal Code*"
                                          type="text"
                                          errorMessage="Enter zip/postal code"
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.zip === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          validate={{
                                            required: {
                                              value: true,
                                              errorMessage:
                                                "Zip code is required",
                                            },
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="9">
                                      <FormGroup className="mb-3">
                                        <PhoneInput
                                          type="text"
                                          className={
                                            !this.state.phonenumberError
                                              ? "phone-input"
                                              : "phone-input invalid"
                                          }
                                          id="basicpill-orgPhone-input"
                                          name="phonenumber"
                                          aria-describedby="Phone Number"
                                          placeholder="Phone Number*"
                                          value={this.state.phonenumber}
                                          maxLength={16}
                                          onChange={this.handlePhoneChange}
                                          onBlur={this.handlePhoneBlur}
                                          required
                                        />
                                        {this.state.phonenumberError ? (
                                          <div
                                            className="text-danger"
                                            style={{
                                              fontSize: "80%",
                                              marginTop: "0.25rem",
                                            }}
                                          >
                                            {this.state.phonenumberError}
                                          </div>
                                        ) : null}
                                        
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6" style={{ paddingRight: "0px" }}>
                                      <FormGroup className="mb-3">
                                        <AvField
                                          name="email"
                                          value={this.state.email}
                                          placeholder="Email*"
                                          type="text"
                                          errorMessage="Enter valid email id."
                                          className={`form-control ${
                                            this.state.currentStepError &&
                                            this.state.email === ""
                                              ? "is-invalid-border"
                                              : null
                                          }`}
                                          onChange={this.handleInputChange}
                                          validate={{
                                            // email: true,
                                            required: true,
                                            pattern: {
                                              value: /^[^ @]+$/,
                                              errorMessage:
                                                "Enter a valid email id.",
                                            },
                                          }}
                                          style={{
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px",
                                          }}
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col lg="6" style={{ paddingLeft: "0px" }}>
                                      <FormGroup className="mb-3">
                                        <AvField
                                          type="text"
                                          name="emailDomain"
                                          value={this.state.emailDomain}
                                          onChange={this.handleInputChange}
                                          style={{
                                            borderTopLeftRadius: "0px",
                                            borderBottomLeftRadius: "0px",
                                            borderLeft: "0px",
                                            backgroundColor: "lightgray",
                                          }}
                                          disabled
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col lg="9">
                                      <FormGroup className="mb-3">
                                        {/* make this into reusable component */}
                                        <label htmlFor="resourcegroupfield">
                                          Resource Group
                                        </label>
                                        <Creatable
                                          name="rgs"
                                          id="resourcegroupfield"
                                          isClearable
                                          onChange={this.handleSelectChange}
                                          formatCreateLabel={(inputValue) =>
                                            `Create ResourceGroup "${inputValue}"`
                                          }
                                          placeholder={
                                            "Select/create a resource group"
                                          }
                                          options={
                                            this.props.organizations &&
                                            this.props.organizations.length !==
                                              0
                                              ? this.props.organizations[0].resourceGroups.map(
                                                  (rg) => {
                                                    return {
                                                      value: rg.resourcename,
                                                      label: rg.resourcename,
                                                    };
                                                  }
                                                )
                                              : null
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </AvForm>
                                {this.props.error ? (
                                  <Alert color="danger">
                                    Error registering account, please check your
                                    details
                                    {this.props.reg_acct_error}
                                  </Alert>
                                ) : null}
                              </div>

                              <div
                                style={{
                                  height: "82%",
                                  borderLeft: "6px solid block",
                                  position: "absolute",
                                  left: "50%",
                                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                                  width: "0.1px",
                                }}
                              />
                              <div className="col-sm-12 col-md-6">
                                <div className="d-flex flex-column">
                                  <div>
                                    <p>
                                      <strong style={{ fontSize: "1.2em" }}>
                                        Step 2:
                                      </strong>{" "}
                                      <span>
                                          Follow the steps below to pair the account with CloudCatcher and{' '}
                                          <Link
                                            href="https://resource-explorer.console.aws.amazon.com/resource-explorer/home?region=us-east-1#/home"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            underline="hover"
                                            sx={{ color: '#1976d2', cursor: 'pointer' }}
                                          >
                                            enable AWS Resource Explorer
                                            <sup>
                                              <OpenInNewIcon fontSize="small" />
                                            </sup>
                                          </Link>{' '}
                                          for seamless integration.
                                        </span>
                                    </p>
                                  </div>
                                  <hr />
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      gap: "16px", // Adjust the gap value for the spacing between buttons
                                    }}
                                  >
                                    <button
                                      style={{
                                        border: "solid 1px #F98125",
                                        padding: "10px 20px",
                                        flex: "1", // Ensures equal size for the buttons
                                      }}
                                      className="btn"
                                      disabled={this.props.isReleaseDetailsGetting}
                                      onClick={this.handleManualPairingGuide}
                                    >
                                      <strong>Pairing Guide</strong>
                                    </button>

                                    <button
                                      style={{
                                        border: "solid 1px #F98125",
                                        padding: "10px 20px",
                                        flex: "1", // Ensures equal size for the buttons
                                      }}
                                      className="btn"
                                      disabled={this.props.isReleaseDetailsGetting}
                                      onClick={this.handleResourceExplorerGuide} // Replace with your second action
                                    >
                                      <strong>Resource Explorer Guide</strong>
                                    </button>
                                  </div>
                                  <hr
                                    style={{
                                      backgroundColor: "rgba(0, 0, 0, 0.1)",
                                      width: "100%",
                                    }}
                                  />

                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <button
                                      id="showLinkBtnId"
                                      className="btn btn-link"
                                      onClick={() => {
                                        showTextArea = !showTextArea;
                                        if (showTextArea) {
                                          document.getElementById(
                                            "showLinkBtnId"
                                          ).textContent = "Hide Link";
                                          document.getElementById(
                                            "consoleBodyId"
                                          ).style.display = "block";
                                        } else {
                                          document.getElementById(
                                            "showLinkBtnId"
                                          ).textContent = "Show Link";
                                          document.getElementById(
                                            "consoleBodyId"
                                          ).style.display = "none";
                                        }
                                      }}
                                    >
                                      Show Link
                                    </button>
                                    <button
                                      id="clickToCopyBtnId"
                                      className="btn btn-link"
                                      onClick={this.handleClicktoCopy}
                                    >
                                      Copy Link
                                    </button>
                                    <button
                                      className="btn btn-link"
                                      onClick={() =>
                                        window.open(
                                          this.linkTextAreaRef.current
                                            .textContent
                                        )
                                      }
                                    >
                                      Click to Launch
                                    </button>
                                    <Tooltip
                                      title="Please make sure you are logged in the AWS account you are onboarding, prior to completing this step.
                                                Open a new tab in your browser. Copy the link above and paste it in the URL bar of this new tab.
                                                Complete setting up a role through AWS IAM role creation console, make sure that the role's name is set as:
                                                CloudCatcherTrustRole"
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      onClose={() =>
                                        this.setState({
                                          showCopyLinkHelp: false,
                                        })
                                      }
                                      open={this.state.showCopyLinkHelp}
                                      placement="top"
                                      disableHoverListener
                                    >
                                      <button
                                        className="btn btn-sm btn-link text-dark"
                                        onClick={() =>
                                          this.setState({
                                            showCopyLinkHelp:
                                              !this.state.showCopyLinkHelp,
                                          })
                                        }
                                      >
                                        <i className="mdi mdi-help-circle mr-1"></i>
                                      </button>
                                    </Tooltip>
                                  </div>

                                  <div
                                    className="mb-3"
                                    id="consoleBodyId"
                                    style={{
                                      display: "none",
                                    }}
                                  >
                                    <textarea
                                      readOnly
                                      ref={this.linkTextAreaRef}
                                      className="form-control"
                                      rows="6"
                                      name="urlLink"
                                      value={`https://console.aws.amazon.com/iam/home#/roles$new?step=review&roleType=crossAccount&accountID=${this.props.aws}&policies=arn%3Aaws%3Aiam%3A%3Aaws%3Apolicy%2FReadOnlyAccess&roleName=${this.state.roleName}&externalId=${this.state.externalId}&trustedEntityType=AWS_ACCOUNT&isThirdParty=true&awsAccount=${this.props.aws}`}
                                      onChange={this.handleInputChange}
                                    />
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                    width: "100%",
                                  }}
                                />
                                <div>
                                  <div>
                                    <p>
                                      <strong style={{ fontSize: "1.2em" }}>
                                        Step 3:
                                      </strong>{" "}
                                      <span style={{ fontSize: "1em" }}>
                                        Verify the newly created Pairing
                                      </span>
                                    </p>
                                    <Row>
                                      <Col lg="10">
                                        <label>External Id</label>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col lg="10">
                                        <FormGroup className="mb-3">
                                          <input
                                            type="text"
                                            // name="externalId"
                                            disabled={true}
                                            value={this.state.externalId}
                                            className={`form-control`}
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col lg="2">
                                        <Tooltip
                                          title={
                                            this.state.copy
                                              ? "Copied"
                                              : "Click to copy"
                                          }
                                        >
                                          <IconButton
                                            onClick={this.handleClickExternalId}
                                          >
                                            <ContentCopyIcon
                                              color={
                                                this.state.copy ? "primary" : ""
                                              }
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col>
                                        {this.state.verifyAccountError && (
                                          <input
                                            type="text"
                                            style={{
                                              marginBottom: "10px",
                                            }}
                                            name="roleName"
                                            value={this.state.roleName}
                                            placeholder="Role Name*"
                                            errorMessage="Enter desired Role Name."
                                            className={`form-control ${
                                              this.state.currentStepError &&
                                              this.state.roleName === ""
                                                ? "is-invalid-border"
                                                : null
                                            }`}
                                            onChange={this.handleInputChange}
                                            validate={{
                                              required: true,
                                            }}
                                          />
                                        )}
                                      </Col>
                                    </Row>
                                  </div>
                                  <div>
                                    <button
                                      className="btn w-lg btn-primary"
                                      disabled={
                                        this.isAccountInvalid() ||
                                        this.props.accountStatusLoading ||
                                        this.isAccountVerified()
                                      }
                                      onClick={this.verifyPairedAccountStatus}
                                    >
                                      {this.state.verifyAccountSuccess ? (
                                        <>Pairing Verified</>
                                      ) : (
                                        <>Verify Pairing</>
                                      )}
                                    </button>
                                    <div>
                                      {this.props.accountStatusLoading && (
                                        <small className="alert-danger header">
                                          {" "}
                                          Wait a while...
                                        </small>
                                      )}

                                      {this.state.verifyAccountSuccess && (
                                        <small className="alert-success header">
                                          {" "}
                                          Account Paired Successfully!!!
                                        </small>
                                      )}
                                      {this.state.verifyAccountError && (
                                        <small className="alert-danger header">
                                          {this.props.accountStatusError}
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <hr
                                  style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            </div>
                          </TabPane>
                        </TabContent>
                      </div>
                    )}

                    <Snackbar
                      open={this.state.registerAccountError}
                      autoHideDuration={5000}
                    >
                      <Alert severity="error">
                        Error while creating the account.{" "}
                        {this.props.reg_acct_error}
                      </Alert>
                    </Snackbar>

                    <div
                      className="row"
                      style={{
                        marginTop: "30px",
                        gap: "10px",
                        display: "flex",
                        justifyContent: "right",
                      }}
                    >
                      <div>
                        <button
                          className="btn w-lg btn-primary"
                          disabled={
                            this.isCurrentStepInvalid() || this.props.isRegisteringOrgAccount
                          }
                          onClick={this.handleNext}
                        >
                          {this.state.loading ? (
                            <span>
                              <i className="fa fa-spinner fa-spin" />{" "}
                              Submitting...
                            </span>
                          ) : this.state.step === 0 ? (
                            "Submit"
                          ) : (
                            "Next"
                          )}
                        </button>
                      </div>
                    </div>

                    <>
                      <Modal isOpen={this.props.registerOrgAccountMessage ? true : false}>
                        <ModalBody>Account successfully added</ModalBody>
                        <ModalFooter>
                          <Button onClick={this.closeAcctSuccesmsg}>
                            close
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </>                  
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const {
    account,
    accounts,
    organizations,
    reg_acct_error,
    loading,
    error,
    message,
    registerOrgAccountMessage,
    isRegisteringOrgAccount
  } = state.orgAccount;
  const { aws } = state.awsAcc;
  const {
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,
  } = state.releaseNotes;

  const {
    // functionCountsLoading,
    // functionCountsTotal,
    // functionCountsError,
    // functionCountsProgress,

    accountStatusLoading,
    accountStatusResult,
    accountStatusError,
  } = state.scanService;

  return {
    account,
    accounts,
    organizations,
    reg_acct_error,
    loading,
    message,
    registerOrgAccountMessage,
    isRegisteringOrgAccount,

    aws,
    error,
    isReleaseDetailsGetting,
    getReleaseDetailsMessage,
    getReleaseDetailsError,
    releaseDetails,

    // functionCountsLoading,
    // functionCountsTotal,
    // functionCountsError,
    // functionCountsProgress,

    accountStatusLoading,
    accountStatusResult,
    accountStatusError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getListOfOrgsAndAccts: () => {
      dispatch(getListOfOrgsAndAccts());
    },
    registerOrgAccount: (data) => {
      dispatch(registerOrgAccount(data));
    },
    resetOrgAccountStatus: () => {
      dispatch(registerOrgAccountSuccessful(null));
    },
    clearAllOrgAccountMessages: () => {
      dispatch(clearAllOrgAccountMessages());
    },
    getAwsAcc: () => {
      dispatch(getAwsAcc());
    },
    getReleaseDetails: (fileName) => {
      dispatch(getReleaseDetailsRequest(fileName));
    },
    clearAllReleaseMessages: () => {
      dispatch(clearAllReleaseMessages());
    },
    verifyAccountStatus: (accountNumber, roleName, externalId) => {
      dispatch(
        verifyAccountStatusRequest({ accountNumber, roleName, externalId })
      );
    },
    clearAllScanServiceMessages: () => {
      dispatch(clearAllScanServiceMessages());
    },
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(OrgAccount);
