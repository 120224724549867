import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, CircularProgress, Typography } from "@mui/material";
import WorkloadAssessment from "./components/WorkloadAssessment";
import {
  getWafrQuestionnaire,
  clearAllWafrQuestionnaireMessages,
} from "./../../store/wafrQuestionnaire/wafrQuestionnaireSlice";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { enqueueSnackbar } from "notistack";

const WorkloadAssessmentPage = () => {
  const { workloadId } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    updateWafrAnswerMessage,
    isUpdatingWafrAnswer,
    updateWafrAnswerError,
  } = useSelector((state) => state.wafrQuestionnaire);

  const {
    orgCode,
    accCode,
    accNumber,
    assessmentName,
    frameworkName,
    ownerName,
    approverName,
  } = location.state || {};

  const {
    isGettingWafrQuestionnaire,
    wafrQuestionnaire,
    getWafrQuestionnaireError,
  } = useSelector((state) => state.wafrQuestionnaire);

  useEffect(() => {
    if (workloadId && orgCode && accCode) {
      dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));
    }

    return () => {
      dispatch(clearAllWafrQuestionnaireMessages());
    };
  }, [workloadId, dispatch]);

  useEffect(() => {
    if (updateWafrAnswerMessage) {
      enqueueSnackbar(updateWafrAnswerMessage, { variant: "success" });
      console.log("updateWafrAnswerMessage = ", updateWafrAnswerMessage);
      if (workloadId && orgCode && accCode) {
        dispatch(getWafrQuestionnaire({ workloadId, orgCode, accCode }));
      }
    }
    if (updateWafrAnswerError) {
      enqueueSnackbar(updateWafrAnswerError, { variant: "error" });
      console.log("updateWafrAnswerError = ", updateWafrAnswerError);
    }
    if (updateWafrAnswerMessage || updateWafrAnswerError) {
      dispatch(clearAllWafrQuestionnaireMessages());
    }
  }, [
    updateWafrAnswerMessage,
    updateWafrAnswerError,
    workloadId,
    orgCode,
    accCode,
    dispatch,
  ]);

  return (
    <Box
      className="page-content"
      sx={{
        backgroundColor: "#F9F9F9",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CssBaseline />
      {isGettingWafrQuestionnaire ? (
        <CircularProgress />
      ) : getWafrQuestionnaireError ? (
        <Typography color="error">{getWafrQuestionnaireError}</Typography>
      ) : null}
      <WorkloadAssessment
        wlaData={wafrQuestionnaire}
        orgCode={orgCode}
        accCode={accCode}
        accNumber={accNumber}
        assessmentName={assessmentName}
        frameworkName={frameworkName}
        ownerName={ownerName}
        approverName={approverName}
      />
    </Box>
  );
};

export default WorkloadAssessmentPage;
