export const REGISTER_ORG_ACCOUNT = 'register_org_account';
export const REGISTER_ORG_ACCOUNT_SUCCESSFUL = 'register_org_account_successful';
export const REGISTER_ORG_ACCOUNT_FAILED = 'register_org_account_failed';

export const UPDATE_ORG_ACCOUNT = 'update_org_account';
export const UPDATE_ORG_ACCOUNT_SUCCESSFUL = "update_org_account_successful";
export const UPDATE_ORG_ACCOUNT_FAILED = "update_org_account_failed";

export const GET_LIST_OF_ORGS_AND_ACCOUNTS = 'get_list_of_orgs_and_accounts';
export const GET_LIST_OF_ORGS_AND_ACCOUNTS_SUCCESSFUL = 'get_list_of_orgs_and_accounts_successful';
export const GET_LIST_OF_ORGS_AND_ACCOUNTS_FAILED = 'get_list_of_orgs_and_accounts_failed';

export const ADD_ORGANIZATION = 'add_organization';
export const ADD_ORGANIZATION_SUCCESS = 'add_organization_success';
export const ADD_ORGANIZATION_FAILURE = 'add_organization_failure';

export const RESET_ORG_ACCOUNT_STATUS = "reset_org_account_status";

export const CLEAR_ALL_ORG_ACCOUNT_MESSAGES = "clear_all_org_account_messages";
export const CLEAR_ALL_ORG_ACCOUNT_MESSAGES_SUCCESSFUL = "clear_all_org_account_messages_successful";