import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Divider,
  Checkbox,
  FormControlLabel,
  Button,
  Popover,
  Chip,
} from "@mui/material";
import RiskReasonDisplay from "./RiskReasonDisplay";
import ApplicabilityOption from "./ApplicabilityOption";
import NotesBox from "./NotesBox";
import { updateWafrAnswer } from "../../../store/wafrQuestionnaire/wafrQuestionnaireSlice";

const SectionQuestionsDetailsPanel = ({
  sectionQuestionDetailList,
  selectedQuestion,
  sectionQuestionLabel,
  orgCode,
  accCode,
  accNumber,
}) => {
  const dispatch = useDispatch();
  const {
    updateWafrAnswerMessage,
    isUpdatingWafrAnswer,
    updateWafrAnswerError,
  } = useSelector((state) => state.wafrQuestionnaire);

  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDescription, setCurrentDescription] = useState("");

  const selectedQuestionDetails = sectionQuestionDetailList[selectedQuestion];
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (selectedQuestionDetails?.choices?.length > 0) {
      return selectedQuestionDetails.choices
        .filter((choice) => choice.status === "SELECTED")
        .map((choice) => choice.choiceId);
    }
    return [];
  });
  console.log("selectedOptions : ", selectedOptions);

  const [notApplicable, setNotApplicable] = useState(
    !selectedQuestionDetails.applicable
  );

  const [notes, setNotes] = useState("");
  const [reason, setReason] = useState(
    selectedQuestionDetails.reason ? selectedQuestionDetails.reason : "NONE"
  );

  // State for Applicability and Notes per choice
  const [choiceStates, setChoiceStates] = useState(() =>
    (selectedQuestionDetails?.choices || []).reduce((acc, choice) => {
      acc[choice.choiceId] = {
        notApplicable: choice.status === "NOT_APPLICABLE" ? true : false,
        reason: choice.reason,
        notes: "",
      };
      return acc;
    }, {})
  );
  // console.log("ChoiceStates: " , choiceStates);

  const handleInfoClick = (event, description) => {
    setAnchorEl(event.currentTarget);
    setCurrentDescription(description);
  };

  const handleInfoClose = () => {
    setAnchorEl(null);
    setCurrentDescription("");
  };

  const handleOptionChange = (choiceId) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(choiceId)
        ? prevSelected.filter((id) => id !== choiceId)
        : [...prevSelected, choiceId]
    );
  };

  const removeSelectedOption = (choiceId) => {
    setSelectedOptions((prevSelectedChoices) =>
      prevSelectedChoices.filter(
        (prevSelectedChoice) => prevSelectedChoice !== choiceId
      )
    );
  };

  const getOriginalStatus = () => {
    const originalSelectedChoices = [];
    const originalUpdateChoiceDtos = {};
    selectedQuestionDetails.choices.forEach((choice) => {
      if (choice.status === "SELECTED") {
        // Add choiceId to selectedChoices
        originalSelectedChoices.push(choice.choiceId);
      } else {
        // Add choiceId as key to updateChoiceDtos
        originalUpdateChoiceDtos[choice.choiceId] = {
          status: choice.status,
          reason: choice.reason,
          notes: "",
        };
      }
    });
    return [originalSelectedChoices, originalUpdateChoiceDtos];
  };

  const getUpdatedSelectedOptions = () => {
    const updatedSelectedOptions = [];

    const choiceKeys = Object.keys(choiceStates);
    choiceKeys.forEach((choiceKey) => {
      const isChoiceNotApplicable = choiceStates[choiceKey]?.notApplicable;
      if (!isChoiceNotApplicable) {
        if (
          selectedOptions.some((selectedOption) => selectedOption === choiceKey)
        ) {
          updatedSelectedOptions.push(choiceKey);
        }
      }
    });
    return updatedSelectedOptions;
  };

  const getUpdatedFilteredChoiceStates = (updatedSelectedOptions) => {
    const filteredChoiceStates = Object.keys(choiceStates)
      .filter((key) => {
        const existingChoice = selectedQuestionDetails?.choices.find(
          (choice) => choice.choiceId === key
        );
        const wasNotApplicable =
          existingChoice?.status === "NOT_APPLICABLE" &&
          !choiceStates[key].notApplicable;

        // Keep choices:
        // 1. Previously NOT_APPLICABLE but now selected (wasNotApplicable).
        // 2. Choices that need to update their status from previously selected to something else.
        return (
          wasNotApplicable ||
          !(
            updatedSelectedOptions.includes(key) &&
            existingChoice?.status === "SELECTED"
          )
        );
      })
      .reduce((acc, key) => {
        acc[key] = choiceStates[key]; // Add the key-value pair to the new object
        return acc;
      }, {});

    const updatedFilteredChoiceStates = Object.fromEntries(
      Object.entries(filteredChoiceStates)
        .map(([key, value]) => {
          const existingChoice = selectedQuestionDetails?.choices.find(
            (choice) => choice.choiceId === key
          );
          const wasNotApplicable =
            existingChoice?.status === "NOT_APPLICABLE" &&
            updatedSelectedOptions.includes(key);

          return [
            key,
            {
              status: value.notApplicable
                ? "NOT_APPLICABLE"
                : wasNotApplicable
                ? "UNSELECTED" // Mark it as UNSELECTED for the update.
                : updatedSelectedOptions.includes(key)
                ? "SELECTED"
                : "UNSELECTED",
              reason:
                value.reason && value.reason.trim() !== ""
                  ? value.reason
                  : "NONE",
              notes: value.notes,
            },
          ];
        })
        // Filter out choices with status: SELECTED
        .filter(([_, value]) => value.status !== "SELECTED")
    );
    return updatedFilteredChoiceStates;
  };

  const handleSave = () => {
    const updatedSelectedOptions = getUpdatedSelectedOptions();
    const updatedFilteredChoiceStates = getUpdatedFilteredChoiceStates(
      updatedSelectedOptions
    );

    console.log("updatedFilteredChoiceStates:", updatedFilteredChoiceStates);

    const [originalSelectedChoices, originalUpdateChoiceDtos] =
      getOriginalStatus();

    const finalChoiceUpdateDtos = !notApplicable
      ? updatedFilteredChoiceStates
      : originalUpdateChoiceDtos;

    const updateWafrAnswerPayload = {
      orgCode: orgCode,
      accountCode: accCode,
      accountNumber: accNumber,
      workloadId: selectedQuestionDetails?.workloadId,
      lensArn: selectedQuestionDetails?.lensArn,
      answers: [
        {
          questionId: selectedQuestionDetails.questionId,
          choiceUpdateDtos: finalChoiceUpdateDtos,
          applicable: notApplicable ? false : true,
          notes: notes ? notes : "",
          reason: reason && reason.trim() !== "" ? reason : "NONE",
          selectedChoices: notApplicable
            ? originalSelectedChoices
            : [...updatedSelectedOptions],
        },
      ],
    };

    console.log("updateWafrAnswerPayload = ", updateWafrAnswerPayload);
    dispatch(updateWafrAnswer(updateWafrAnswerPayload));
  };

  const isAnswerUpdated = () => {
    const originalNotApplicable = !selectedQuestionDetails.applicable;
    // console.log("notApplicable = ", notApplicable);
    // console.log("selectedQuestionDetails.applicable = ", selectedQuestionDetails.applicable);

    if (notApplicable !== originalNotApplicable) {
      console.log("Returning true");
      return true;
    }

    const updatedSelectedOptions = getUpdatedSelectedOptions();
    const updatedFilteredChoiceStates = getUpdatedFilteredChoiceStates(
      updatedSelectedOptions
    );
    // console.log("updatedSelectedOptions : ", updatedSelectedOptions);
    // console.log("updatedFilteredChoiceStates : ", updatedFilteredChoiceStates);

    const [originalSelectedChoices, originalUpdateChoiceDtos] =
      getOriginalStatus();
    // console.log("originalSelectedChoices : ", originalSelectedChoices);
    // console.log("originalUpdateChoiceDtos : ", originalUpdateChoiceDtos);

    const isSelectedOptionsUpdated = () => {
      if (updatedSelectedOptions.length !== originalSelectedChoices.length) {
        return true;
      }

      // Check if any value is different
      const originalSet = new Set(originalSelectedChoices);
      return updatedSelectedOptions.some((option) => !originalSet.has(option));
    };

    // Compare objects for updatedFilteredChoiceStates and originalUpdateChoiceDtos
    const isFilteredChoiceStatesUpdated = () => {
      const updatedKeys = Object.keys(updatedFilteredChoiceStates);
      const originalKeys = Object.keys(originalUpdateChoiceDtos);

      if (updatedKeys.length !== originalKeys.length) {
        return true; // Number of keys differ, so there are changes
      }

      // Check each key-value pair for differences
      return updatedKeys.some((key) => {
        const updatedValue = updatedFilteredChoiceStates[key];
        const originalValue = originalUpdateChoiceDtos[key];

        // If the key is missing in the original or the values don't match
        return (
          !originalValue ||
          updatedValue.status !== originalValue.status ||
          updatedValue.reason !== originalValue.reason ||
          updatedValue.notes !== originalValue.notes
        );
      });
    };

    // Return true if any of the checks indicate changes
    // console.log("isSelectedOptionsUpdated() = ", isSelectedOptionsUpdated());
    // console.log("isFilteredChoiceStatesUpdated() = ", isFilteredChoiceStatesUpdated());
    return (
      isSelectedOptionsUpdated() ||
      isFilteredChoiceStatesUpdated() ||
      notApplicable !== originalNotApplicable
    );
  };

  const updateChoiceState = (choiceId, field, value) => {
    setChoiceStates((prev) => ({
      ...prev,
      [choiceId]: {
        ...prev[choiceId],
        [field]: value,
      },
    }));
  };

  const isPopoverOpen = Boolean(anchorEl);

  return (
    <Box
      sx={{
        overflowY: "auto",
        height: "100%",
      }}
    >
      {/* Question Title */}
      <Typography sx={{ fontSize: "0.8rem" }} fontWeight="bold">
        {sectionQuestionLabel} {selectedQuestion + 1}{" "}
        {selectedQuestionDetails.questionTitle}
      </Typography>

      {/* Question Details */}
      <Typography sx={{ fontSize: "0.6rem", marginTop: "0.5rem" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {/* Risk and Reason */}
          <RiskReasonDisplay
            label="Risk"
            value={selectedQuestionDetails.risk}
            type="risk"
          />
          {selectedQuestionDetails.risk && selectedQuestionDetails.reason ? (
            <span style={{ margin: "0 0.5rem" }}>|</span>
          ) : null}
          <RiskReasonDisplay
            label="Reason"
            value={selectedQuestionDetails.reason}
            type="reason"
          />
        </Box>
        {/* Popover for showing the description */}
        <Popover
          open={isPopoverOpen}
          anchorEl={anchorEl}
          onClose={handleInfoClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "0.5rem", maxWidth: "300px" }}>
            <Typography sx={{ fontSize: "0.7rem" }}>
              {currentDescription}
            </Typography>
          </Box>
        </Popover>
      </Typography>

      {/* Save Button */}
      <Button
        variant="contained"
        size="small"
        color="primary"
        // sx={{backgroundColor:"#F98125"}}
        disabled={
          !isAnswerUpdated() ||
          isUpdatingWafrAnswer ||
          (notApplicable
            ? !reason
            : Object.values(choiceStates)?.some(
                (choice) => choice.notApplicable && !choice.reason
              ))
        }
        onClick={handleSave}
      >
        {isUpdatingWafrAnswer ? "Saving" : "Save"}
      </Button>

      {/* Question Level Applicability Option */}
      <ApplicabilityOption
        questionId={selectedQuestionDetails.questionId}
        notApplicable={notApplicable}
        setNotApplicable={setNotApplicable}
        reason={reason}
        setReason={setReason}
      />

      <Box
        sx={{
          padding: "0.5rem",
          borderRadius: "0.25rem",
          border: "1px solid #ccc",
          marginRight: "0.5rem",
        }}
      >
        <Box
          sx={{
            marginTop: "0.25rem",
            marginRight: "0.5rem",
            padding: "0.5rem",
            borderRadius: "0.25rem",
            backgroundColor: "#F0F0F0",
          }}
        >
          Best Practices
        </Box>

        <Box sx={{ marginTop: "0.25rem", marginRight: "0.5rem" }}>
          {selectedQuestionDetails?.choices?.map((choice, index) => (
            <Box key={choice.choiceId || index}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={selectedOptions.includes(choice.choiceId)}
                      onChange={() => handleOptionChange(choice.choiceId)}
                      disabled={
                        notApplicable ||
                        choiceStates[choice.choiceId]?.notApplicable
                      }
                    />
                  }
                  label={
                    <Typography sx={{ fontSize: "0.8rem" }}>
                      {sectionQuestionLabel} {selectedQuestion + 1}.{index + 1}{" "}
                      {choice.title}{" "}
                      <Button
                        sx={{
                          fontSize: "0.7rem",
                          textTransform: "none",
                          marginLeft: "0.5rem",
                        }}
                        onClick={(event) =>
                          handleInfoClick(event, choice.description)
                        }
                      >
                        Info
                      </Button>
                    </Typography>
                  }
                />
                <Box>
                  {choice.automated ? (
                    <Chip
                      label={`Nature: Automated`}
                      sx={{ fontSize: "0.7rem" }}
                    />
                  ) : (
                    <Chip
                      label={`Nature: Manual`}
                      sx={{ fontSize: "0.7rem" }}
                    />
                  )}
                </Box>
              </Box>
              <Box sx={{ paddingLeft: "2rem" }}>
                {/* Choice Level Applicability Option */}
                <ApplicabilityOption
                  questionId={choice.choiceId}
                  notApplicable={choiceStates[choice.choiceId]?.notApplicable}
                  setNotApplicable={(value) => {
                    updateChoiceState(choice.choiceId, "notApplicable", value);
                    if (value) {
                      removeSelectedOption(choice.choiceId);
                    }
                  }}
                  reason={choiceStates[choice.choiceId]?.reason}
                  setReason={(value) =>
                    updateChoiceState(choice.choiceId, "reason", value)
                  }
                  parentNotApplicable={notApplicable}
                >
                  {/* Notes Box */}
                  <NotesBox
                    notes={choiceStates[choice.choiceId]?.notes}
                    setNotes={(value) =>
                      updateChoiceState(choice.choiceId, "notes", value)
                    }
                    noRows={2}
                  />
                </ApplicabilityOption>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Notes Box */}
      <Box sx={{ marginTop: "0.5rem" }}>
        <NotesBox notes={notes} setNotes={setNotes} noRows={4} />
      </Box>

      {/* Divider */}
      <Divider sx={{ marginTop: "0.5rem", marginBottom: "0.5rem" }} />
    </Box>
  );
};

export default SectionQuestionsDetailsPanel;
