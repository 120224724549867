import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Chip, Snackbar, Typography } from "@mui/material";
import { Alert } from "@material-ui/lab";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import EditIcon from "@mui/icons-material/Edit";

import styles from "./style.module.css";
import loadingGif from "./../../assets/images/aws/loadingGif.gif";

import { Container, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import jwt_decode from "jwt-decode";

import FileUploadHistoryTable from "./components/FileUploadHistoryTable";
import IaaCHeaderComponent from "./components/IaaCHeaderComponent";

import {
  getListOfAccts,
  getIaacFileHistoryRequest,
  getIaacSubscriptionRequest,
  clearAllIaacSubscriptionMessages,
  getIaacMetricsRequest,
  clearAllIaacMessages,
  getLicensingData,
} from "../../store/actions";
import FileUpload from "./components/FileUpload";

import LicenseSelection from "./components/LicenseSelection";

import { subscriptionNames } from "./components/LicenseSelection";
import InaccessibleResources from "../FreeTrial/InaccessibleResources";
import getUserRole from "../../helpers/jwt-token-access/userRole";
import ContactAdmin from "./components/ContactAdmin";

const freeLicenseName = process.env.REACT_APP_FREE_ORGANIZATION_LICENSE;
const freeLicenses = [freeLicenseName];

const allowedRoles = ["Administrator"];

const IACManagement = (props) => {
  const [orgcode, setOrgcode] = useState(null);
  const [username, setUsername] = useState(null);

  const [fileHistory, setFileHistory] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const [isFileUploadModalOpen, setIsFileUploadModalOpen] = useState(false);
  const [scanStatus, setScanStatus] = useState(null);

  // const [license, setLicense] = useState(null);
  // const [page, setPage] = useState("licenseSelection");
  const [page, setPage] = useState("");
  const [subscriptionUpdateTime, setSubscriptionUpdateTime] = useState(null);
  const [licenseSelectionMode, setLicenseSelectionMode] = useState("create");

  // To store subscription limit retrieved by IaaCHeaderComponent
  const [templatesUploadCount, setTemplatesUploadCount] = useState(null);
  const { licenseName: userLicense, message } = props;

  const userRole = getUserRole();

  console.log("orgcode = ", orgcode);
  console.log("username = ", username);
  console.log("userRole = ", userRole);

  const openFileUploadModal = () => {
    setIsFileUploadModalOpen(true);
  };

  const closeFileUploadModal = () => {
    setIsFileUploadModalOpen(false);
  };

  const switchToEditLicense = () => {
    console.log("~~~~~ switchToEditLicense called ");
    setPage("licenseSelection");
    setLicenseSelectionMode("update");
  };

  const getLicensingData = props.getLicensingData;
  const currentLicense = props.licenseData?.data?.licenseName;
  const currentLicenseLoading = props.licenseData?.loading;
  useEffect(() => {
    if (!currentLicense && !currentLicenseLoading) {
      getLicensingData();
    }
  }, [currentLicense, getLicensingData, currentLicenseLoading]);

  useEffect(() => {
    props.dispatchGetListOfAccts();
    let usernameFromToken;
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const authUser = jwt_decode(obj.token);
      // console.log("Obj Token", authUser);
      usernameFromToken = authUser.sub.charAt(0) + authUser.sub.slice(1);
      if (!username) {
        setUsername(usernameFromToken);
      }
    }
  }, []);

  const organizations = props.organizations;
  useEffect(() => {
    if (organizations) {
      if (!orgcode) {
        setOrgcode(organizations[0]?.orgcode);
      }
    }
  }, [organizations]);

  useEffect(() => {
    if (!props.fileHistory && orgcode) {
      console.log("Calling fileHistory API ");
      props.getIaacFileHistory(orgcode);
    }
  }, [orgcode]);

  useEffect(() => {
    if (scanStatus && orgcode) {
      console.log("Calling fileHistory API on completion of scan");
      props.getIaacFileHistory(orgcode);
      props.getIaacMetrics(orgcode);
    }
  }, [scanStatus, orgcode]);

  const fileHistoryProps = props.fileHistory;
  useEffect(() => {
    if (fileHistoryProps) {
      const sortedFileHistory = fileHistoryProps
        .slice()
        .sort((historyA, historyB) => {
          const dateA = new Date(historyA.createdDate);
          const dateB = new Date(historyB.createdDate);

          // Compare dates in descending order
          return dateB - dateA;
        });

      setFileHistory(sortedFileHistory);
    }
  }, [fileHistoryProps]);

  //For displaying success/error messages for short duration
  const iaacFileHistoryMessage = props.getIaacFileHistoryMessage;
  const iaacFileHistoryError = props.getIaacFileHistoryError;
  useEffect(() => {
    if (iaacFileHistoryMessage || iaacFileHistoryError) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
  }, [iaacFileHistoryMessage, iaacFileHistoryError]);

  //For displaying success/error messages for short duration
  const iaacMetricsMessage = props.getIaacMetricsMessage;
  const iaacMetricsError = props.getIaacMetricsError;
  useEffect(() => {
    if (iaacMetricsMessage || iaacMetricsError) {
      setTimeout(() => {
        console.log("About to call clearAllUserListMessages");
        props.clearAllIaacMessages();
      }, 10000); // Hide all messages after 10 seconds
    }
  }, [iaacMetricsMessage, iaacMetricsError]);

  // Initially get current subscription
  useEffect(() => {
    if (orgcode) {
      props.getIaacSubscription(orgcode);
    }
  }, [orgcode]);

  // If subscription is present, show dashboard page, otherwise show license selection page
  const currentSubscription = props.subscription;
  const subscriptionError = props.getIaacSubscriptionError;
  const clearAllIaacSubscriptionMessages =
    props.clearAllIaacSubscriptionMessages;
  useEffect(() => {
    console.log(
      "useEffect for showing page based on currentSubscription ",
      currentSubscription
    );
    if (currentSubscription) {
      console.log("Iaac subscription: ", currentSubscription);
      setPage("dashboard");
      clearAllIaacSubscriptionMessages();
    } else if (subscriptionError) {
      if (userRole === "Administrator") {
        setPage("licenseSelection");
      } else {
        setPage("contactAdmin");
      }
    }
  }, [
    currentSubscription,
    subscriptionError,
    clearAllIaacSubscriptionMessages,
    userRole,
  ]);

  // useEffect to reflect updated subscription
  useEffect(() => {
    console.log("useEffect for reflecting updated subscription ");
    if (subscriptionUpdateTime) {
      console.log(
        "Call getIaacSubscription when subscription time has changed"
      );
      props.getIaacSubscription(orgcode);
    }
  }, [subscriptionUpdateTime, orgcode]);

  console.log("~~~~~~~~~~~~~ index page props :", props);
  console.log("~~~ page: ", page);
  console.log("~~~ licenseSelectionMode: ", licenseSelectionMode);

  let licenseName = "";
  if (props?.subscription?.subscription) {
    if (subscriptionNames.includes(props?.subscription?.subscription)) {
      licenseName = props?.subscription?.subscription;
    } else {
      licenseName = "Enterprise";
    }
  }

  if (freeLicenses.includes(userLicense)) {
    return <InaccessibleResources message={message} />;
  }

  return (
    <React.Fragment>
      {(page === null || page === "") && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            height: "100vh",
            alignItems: "center",
          }}
        >
          <img src={loadingGif} height={40} alt="Loading Spinner" />
        </div>
      )}

      {page === "dashboard" && (
        <>
          <div className="page-content" style={{ backgroundColor: "#fff" }}>
            <Container fluid>
              <div className={styles.headerContainer}>
                <div style={{ display: "flex", gap: "4px" }}>
                  <h2 className={styles.header}>IaC Scanning</h2>
                  {props.licenseData.data.licenseName !==
                    "AwsMarketPlaceLicense" && (
                    <Chip
                      label={`License: ${licenseName || ""}`}
                      component="button"
                      color="primary"
                      variant="outlined"
                      onClick={switchToEditLicense}
                      icon={<EditIcon />}
                      disabled={!allowedRoles.includes(userRole)}
                    />
                  )}
                </div>
                <Button
                  style={{
                    textAlign: "right",
                    backgroundColor: "#F98125",
                    float: "right",
                    borderColor: "#fff",
                  }}
                  size="sm"
                  onClick={openFileUploadModal}
                  // disabled={
                  //   templatesUploadCount >= 0
                  //     ? templatesUploadCount >= props?.subscription?.limit
                  //     : true
                  // }
                >
                  Scan your templates here
                  <FileUploadIcon />
                </Button>
              </div>
              <IaaCHeaderComponent
                orgcode={orgcode}
                username={username}
                subscription={props?.subscription}
                setTemplatesUploadCount={setTemplatesUploadCount}
              />

              <>
                <Modal isOpen={isFileUploadModalOpen} size="lg" centered>
                  <ModalHeader
                    toggle={closeFileUploadModal}
                    style={{ backgroundColor: "#F98125", margin: -1 }}
                  >
                    <h3 className={styles.modalHeader}>
                      Upload and Scan Configuration Files
                    </h3>
                  </ModalHeader>
                  <ModalBody>
                    {templatesUploadCount >= props?.subscription?.limit ? (
                      <div>
                        <Typography
                          variant="body2"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          You have reached the maximum file upload limit. For
                          assistance, please contact support at{" "}
                          <a href="mailto:support.cloudcatcher@cloudnovatech.com">
                            support.cloudcatcher@cloudnovatech.com
                          </a>
                          .
                        </Typography>
                      </div>
                    ) : (
                      <FileUpload
                        orgcode={orgcode}
                        username={username}
                        handleModalOpen={setIsFileUploadModalOpen}
                        scanStatus={scanStatus}
                        setScanStatus={setScanStatus}
                      />
                    )}
                  </ModalBody>
                </Modal>
              </>

              {props.isIaacFileHistoryGetting && (
                <tr>
                  <td
                    colSpan="8"
                    style={{ textAlign: "center", verticalAlign: "middle" }}
                  >
                    <img src={loadingGif} height={40} alt="Loading Spinner" />
                  </td>
                </tr>
              )}
              {fileHistory && fileHistory.length > 0 && (
                <FileUploadHistoryTable fileHistory={fileHistory} />
              )}
            </Container>
          </div>
          {/* Snackbars for fileHistory API */}
          <Snackbar
            open={props.getIaacFileHistoryError !== null}
            autoHideDuration={5000}
          >
            <Alert severity="error">
              Error while getting file history data.{" "}
              {props.getIaacFileHistoryError}
            </Alert>
          </Snackbar>
          <Snackbar
            open={props.getIaacFileHistoryMessage !== null}
            autoHideDuration={5000}
          >
            <Alert severity="success">{props.getIaacFileHistoryMessage}</Alert>
          </Snackbar>
        </>
      )}
      {page === "licenseSelection" && orgcode && (
        <LicenseSelection
          orgcode={orgcode}
          // license={license}
          // setLicense={setLicense}
          licenseSelectionMode={licenseSelectionMode}
          setPage={setPage}
          currentSubscription={props?.subscription}
          setSubscriptionUpdateTime={setSubscriptionUpdateTime}
        />
      )}
      {page === "contactAdmin" && orgcode && <ContactAdmin />}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { organizations } = state.acctList;
  const {
    isIaacFileHistoryGetting,
    getIaacFileHistoryMessage,
    getIaacFileHistoryError,
    fileHistory,
    getIaacMetricsMessage,
    getIaacMetricsError,
  } = state.iaac;

  const {
    isIaacSubscriptionGetting,
    getIaacSubscriptionMessage,
    getIaacSubscriptionError,
    subscription,
  } = state.iaacSubscription;

  return {
    organizations,
    isIaacFileHistoryGetting,
    getIaacFileHistoryMessage,
    getIaacFileHistoryError,
    fileHistory,
    isIaacSubscriptionGetting,
    getIaacSubscriptionMessage,
    getIaacSubscriptionError,
    subscription,
    getIaacMetricsMessage,
    getIaacMetricsError,
    licenseData: state.licensing,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getIaacFileHistory: (orgcode) => {
      dispatch(getIaacFileHistoryRequest(orgcode));
    },
    dispatchGetListOfAccts: () => {
      dispatch(getListOfAccts());
    },
    clearAllIaacMessages: () => {
      dispatch(clearAllIaacMessages());
    },
    getIaacSubscription: (orgcode) => {
      dispatch(getIaacSubscriptionRequest(orgcode));
    },
    clearAllIaacSubscriptionMessages: () => {
      dispatch(clearAllIaacSubscriptionMessages());
    },
    getIaacMetrics: (orgcode) => {
      dispatch(getIaacMetricsRequest(orgcode));
    },
    getLicensingData: () => {
      dispatch(getLicensingData());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(IACManagement);
